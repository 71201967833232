/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Affix,
  Alert,
  Button,
  Card,
  Center,
  Divider,
  Loader,
  Menu,
  Space,
  Text,
  Transition,
} from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowNarrowUp, Calculator, CircleX, Copy, Edit, Printer, Switch2, Trash } from 'tabler-icons-react';
import { ContractStatusEnum } from '../../../../business/contracts/status';
import { duplicar } from '../../../../business/proposals/duplicate';
import { ProposalStatusEnum, getStatusAction, getValidStatuses } from '../../../../business/proposals/status';
import { validarAnaliseFinanceira, validarRevisao } from '../../../../business/proposals/validation';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { ProposalStatusType, UnitOfMeasureType } from '../../../../models/core/cache.type';
import { ContractSearchResponseType } from '../../../../models/core/contracts.type';
import { ProposalInsertType, ProposalType } from '../../../../models/core/proposals.type';
import contractsService from '../../../../services/core/contracts.service';
import proposalsService from '../../../../services/core/proposals.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature } from '../../../../utils/constants.utils';
import { buildFakeAuditObject } from '../../../../utils/helper.utils';
import DataView from './components/DataView';
import StatusChangeForm from './components/StatusChangeForm';
import { Permission } from '../../../../models/core/departments.type';

type Result = {
  loading: boolean;
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    proposalStatusTypes: ProposalStatusType[];
  } | null;
  data: { proposal: ProposalType; contracts: ContractSearchResponseType[] } | null;
};

function ProposalView() {
  const navigate = useNavigate();
  const modals = useModals();
  const [currentUser] = useCurrentUser();
  const { idProposta } = useParams();
  const [scroll, scrollTo] = useWindowScroll();

  const refStatusForm = useRef<any>(null);

  const [exception, setException] = useState<any | null>(null);
  const [result, setResult] = useState<Result>({
    loading: true,
    referenceData: null,
    data: null,
  });
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let { referenceData, data } = result;
      try {
        if (result.referenceData === null) {
          referenceData = {
            unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
            proposalStatusTypes: await cacheUtils.listProposalStatuses(),
          };
        }
        const proposal = await proposalsService.select({ idProposta: Number(idProposta) });
        const contracts = await contractsService.search({
          listaStatus: [
            ContractStatusEnum.AguardandoInicio,
            ContractStatusEnum.EmAndamento,
            ContractStatusEnum.EmProcessamento,
            ContractStatusEnum.EmSubstituicao,
          ],
          idCliente: proposal.idCliente,
          limit: 1000,
          offset: 0,
        });

        data = { proposal, contracts };
      } catch (error: any) {
        showNotification({
          title: 'Proposta - Visualizar',
          message: error?.isBusinessException ? error.description : `Não foi possível carregar a proposta.`,
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, referenceData, data });
      }
    };
    if (result.data === null) {
      fetchData();
    }
  }, [idProposta, navigate, result]);

  const handleAction = async (action: string) => {
    try {
      setResult({ loading: false, referenceData: result.referenceData, data: result.data });

      let tempData: any;
      let completedAction;
      setUpdating(true);

      switch (action) {
        case 'excluir':
          await proposalsService.delete({ idProposta: Number(idProposta) });
          completedAction = 'excluída';
          navigate('/proposals');
          break;
        case 'duplicar':
          tempData = await proposalsService.select({ idProposta: Number(idProposta) });
          tempData = await proposalsService.insert(duplicar(tempData, false) as ProposalInsertType);
          completedAction = 'duplicada';

          setTimeout(() => {
            window.location.href = window.location.href.replace(idProposta!, tempData.idProposta);
          }, 350);
          break;
        case 'reajustar':
          tempData = await proposalsService.select({ idProposta: Number(idProposta) });
          tempData = await proposalsService.insert(duplicar(tempData, true) as ProposalInsertType);
          completedAction = 'duplicada para reajuste';

          setTimeout(() => {
            window.location.href = window.location.href.replace(idProposta!, tempData.idProposta);
          }, 350);
          break;
        case 'imprimir':
          window.open(`${Feature.Home.Proposal.link}/${idProposta}/print`, '_blank')!;
          completedAction = 'aberta para impressão';
          break;
        default:
          break;
      }

      showNotification({
        title: 'Proposta - Visualizar',
        message: `Proposta ${completedAction} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Proposta - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível excluir a proposta.`,
        color: 'red',
      });
    } finally {
      setUpdating(false);
    }
  };

  const showModal = (action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} essa proposta?`,
      size: 'sm',
      closeOnClickOutside: false,
      closeOnEscape: false,
      onConfirm: () => {
        handleAction(action);
      },
    });

  const updateStatus = async (newStatusCode: ProposalStatusEnum) => {
    try {
      setResult({ loading: false, referenceData: result.referenceData, data: result.data });

      let statusValidation = null;
      if (newStatusCode === ProposalStatusEnum.EmAnaliseFinanceira) {
        statusValidation = validarAnaliseFinanceira(result.data!.proposal);
      }

      if (statusValidation) {
        setException({
          title: statusValidation.message,
          data: statusValidation?.list.map((x, i) => {
            return (
              <Text key={i} size="sm" color="dimmed">
                {`- ${x}`}
              </Text>
            );
          }),
        });
      } else {
        modals.openConfirmModal({
          title: `Confirma a ação de status "${getStatusAction(newStatusCode).action}"?`,
          size: 'xl',
          closeOnClickOutside: false,
          closeOnEscape: false,
          closeOnConfirm: false,
          children: (
            <StatusChangeForm
              ref={refStatusForm}
              proposal={result.data!.proposal}
              contracts={result.data!.contracts}
              newStatusCode={newStatusCode}
            />
          ),
          onConfirm: async () => {
            const statusData = refStatusForm.current?.validate() || null;
            if (statusData === null) {
              return;
            }

            try {
              setUpdating(true);
              closeAllModals();

              if (newStatusCode === ProposalStatusEnum.EmRevisao) {
                statusValidation = validarRevisao(
                  await proposalsService.select({ idProposta: Number(idProposta) })
                );

                if (statusValidation) {
                  setException({
                    title: statusValidation.message,
                    data: statusValidation?.list.map((x, i) => {
                      return (
                        <Text key={i} size="sm" color="dimmed">
                          {`- ${x}`}
                        </Text>
                      );
                    }),
                  });

                  return;
                }
              }

              const { idContrato } = await proposalsService.updateStatus({
                ...statusData,
                idProposta: Number(idProposta),
                idContrato: statusData.idContrato,
                codigoPropostaStatus: newStatusCode,
              });

              showNotification({
                title: 'Proposta - Visualizar',
                message: `Status da proposta alterada com sucesso.`,
                color: 'green',
              });

              if (newStatusCode === ProposalStatusEnum.Finalizado) {
                showNotification({
                  title: 'Proposta - Visualizar',
                  message: `Contrato gerado com sucesso.`,
                  color: 'purple',
                  autoClose: 8000,
                });
                navigate(`/contracts/${idContrato}`);
              } else if (newStatusCode === ProposalStatusEnum.EmEdicao) {
                navigate(`/proposals/${idProposta}/edit`);
              } else if (
                newStatusCode === ProposalStatusEnum.EmAnaliseFinanceira ||
                result.data?.proposal?.codigoPropostaStatus === ProposalStatusEnum.EmAnaliseFinanceira
              ) {
                setTimeout(() => {
                  window.location.reload();
                }, 350);
              } else {
                const newPropostaStatus = result.referenceData!.proposalStatusTypes.find(
                  (x) => x.codigoPropostaStatus === newStatusCode
                )!.propostaStatus;

                setResult({
                  ...result,
                  data: {
                    ...result.data!,
                    proposal: {
                      ...result.data!.proposal,
                      codigoPropostaStatus: newStatusCode,
                      propostaStatus: newPropostaStatus,
                      status: [
                        ...result.data!.proposal.status,
                        {
                          ...buildFakeAuditObject(),
                          idPropostaStatus: new Date().valueOf(),
                          codigoPropostaStatus: newStatusCode,
                          propostaStatus: newPropostaStatus,
                          codigoPropostaStatusMotivo: null,
                          observacao: statusData.observacao,
                          idCriadoPor: currentUser.idUsuario,
                          criadoPor: currentUser.nomeCompleto,
                          dataCriacao: moment().format('YYYY-MM-DD HH:mm:ss'),
                        },
                      ],
                      modificadoPor: currentUser.nomeCompleto,
                      dataModificacao: moment().format('YYYY-MM-DD HH:mm:ss') as any,
                    },
                  },
                });
              }
            } catch (error: any) {
              showNotification({
                title: 'Proposta - Visualizar',
                message: error?.isBusinessException
                  ? error.description
                  : `Não foi possível alterar o status da proposta.`,
                color: 'red',
              });
            } finally {
              setUpdating(false);
              closeAllModals();
            }
          },
        });
      }
    } catch (error: any) {
      setException({
        title: error?.isBusinessException
          ? error.description
          : `Não foi possível atualizar o status da proposta.`,
        data: null,
      });
    } finally {
      setUpdating(false);
    }
  };

  const buildStatusButton = () => {
    let statuses: ProposalStatusEnum[] = [];
    if (result.data?.proposal?.codigoPropostaStatus) {
      statuses = getValidStatuses(result.data.proposal.codigoPropostaStatus);
    }
    if (statuses.length === 0) {
      return <Button hidden />;
    }

    const menuItems: ReactNode[] = [];

    for (const status of statuses.filter((x) => x !== ProposalStatusEnum.Cancelado)) {
      const statusAction = getStatusAction(status);
      menuItems.push(
        <Menu.Item
          key={statusAction.action}
          icon={<statusAction.icon size={14} />}
          onClick={() => {
            updateStatus(status);
          }}
        >
          {statusAction.action}
        </Menu.Item>
      );
    }

    if (statuses.find((x) => x === ProposalStatusEnum.Cancelado)) {
      const statusAction = getStatusAction(ProposalStatusEnum.Cancelado);
      menuItems.push(
        <div key={statusAction.action}>
          <Divider />
          <Menu.Item
            key={statusAction.action}
            color="red"
            icon={<statusAction.icon size={14} />}
            onClick={() => {
              updateStatus(ProposalStatusEnum.Cancelado);
            }}
          >
            {statusAction.action}
          </Menu.Item>
        </div>
      );
    }

    return (
      <Menu shadow="md" withArrow data-permission={Permission.ProposalEdit}>
        <Menu.Target>
          <Button
            color="orange"
            leftIcon={<Switch2 size={18} />}
            loading={updating}
            disabled={updating || result.loading}
          >
            Status
          </Button>
        </Menu.Target>

        <Menu.Dropdown style={{ minWidth: 250 }}>{menuItems.map((x) => x)}</Menu.Dropdown>
      </Menu>
    );
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Home.Proposal}
        title="Proposta - Visualizar"
        description="Visualize os detalhes de um proposta."
        buttons={[
          <Button
            key="Imprimir"
            color={Feature.Home.Proposal.color}
            leftIcon={<Printer size={18} />}
            onClick={() => {
              showModal('imprimir');
            }}
            disabled={updating || result.loading}
            loading={updating}
            data-permission={Permission.ProposalPrint}
          >
            Imprimir
          </Button>,
          <Button
            key="Duplicar"
            color="secondary"
            leftIcon={<Copy size={18} />}
            onClick={() => {
              showModal('duplicar');
            }}
            disabled={updating || result.loading}
            loading={updating}
            data-permission={Permission.ProposalAdd}
          >
            Duplicar
          </Button>,
          <Button
            key="Reajustar"
            color="cyan"
            leftIcon={<Calculator size={18} />}
            onClick={() => {
              showModal('reajustar');
            }}
            hidden={result.data?.proposal?.codigoPropostaStatus !== ProposalStatusEnum.Finalizado}
            disabled={updating || result.loading}
            loading={updating}
            data-permission={Permission.ProposalAdd}
          >
            Reajustar
          </Button>,
          <Button
            key="Excluir"
            color="red"
            leftIcon={<Trash size={18} />}
            onClick={() => {
              showModal('excluir');
            }}
            disabled={updating || result.loading}
            loading={updating}
            data-permission={Permission.ProposalDelete}
          >
            Excluir
          </Button>,
          buildStatusButton(),
          <Button
            key="Editar"
            color="accent"
            leftIcon={<Edit size={18} />}
            onClick={() => {
              navigate(`/proposals/${result?.data?.proposal?.idProposta}/edit`, {
                state: {
                  proposal: result.data,
                },
              });
            }}
            hidden={result.data?.proposal?.codigoPropostaStatus !== ProposalStatusEnum.EmEdicao}
            disabled={updating || result.loading}
            data-permission={Permission.ProposalEdit}
          >
            Editar
          </Button>,
        ]}
      />
      {result.data === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          {exception && (
            <div>
              <Alert
                icon={<CircleX size={16} />}
                title={exception.title}
                color="red"
                withCloseButton
                onClose={() => setException(null)}
              >
                {exception?.data || <></>}
              </Alert>
              <Space h="lg" />
            </div>
          )}
          <DataView data={result.data.proposal} referenceData={result.referenceData!} />
        </PageContent>
      )}
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default ProposalView;
