/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import {
  ActionIcon,
  Button,
  Grid,
  Group,
  Input,
  Menu,
  MultiSelect,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  TextInput,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Building,
  Calendar,
  ClearAll,
  FileDots,
  Id,
  Qrcode,
  Search,
  ZoomExclamation,
} from 'tabler-icons-react';
import { getPageFilterInitialValues } from '../../../../../business/events/filter';
import {
  Audiencia,
  AudienciaCodigo,
  Fonte,
  Status,
  StatusCodigo,
  Tipo,
  TipoCodigo,
  TipoCodigo2,
} from '../../../../../business/events/general';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { CompanyType, DocumentType, EventStatusType } from '../../../../../models/core/cache.type';
import { UserType } from '../../../../../models/core/users.type';
import theme from '../../../../../theme';

type FilterData = {
  idEmpresa: string | null;
  listaAudiencia: string[];
  listaTipo: string[];
  listaStatus: string[];
  listaResponsavel: string[];
  fonte: string | undefined;
  idContrato: number | undefined;
  idEntidade: number | undefined;
  idProposta: number | undefined;
  dataInicial: Date | undefined;
  dataFinal: Date | undefined;
  idDocumentoTipo: string | null;
  numeroDocumento: string | null;
};

type FilterProps = {
  companies: CompanyType[];
  eventStatuses: EventStatusType[];
  users: UserType[];
  documents: DocumentType[];
  filter(data: FilterData, totalItems: number | null): void;
  open(idEvento: number): void;
  clear(): void;
  loading: boolean;
};

// TODO: App: 012 - save the filter temporarily along with the cached data
function Filter(props: FilterProps) {
  const [currentUser] = useCurrentUser();
  const refEventId = useRef<any>();
  const queryParams = new URLSearchParams(useLocation().search);
  const pageFilter = queryParams.get('filter') || '';
  const pageFilterInitialValues = getPageFilterInitialValues(pageFilter);

  const form = useForm<FilterData>({
    initialValues: {
      idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
      listaAudiencia: [],
      listaTipo: [],
      // listaStatus: [],
      listaResponsavel: [],
      fonte: undefined,
      idContrato: undefined,
      idEntidade: undefined,
      idProposta: undefined,
      // dataInicial: null,
      // dataFinal: null,
      idDocumentoTipo: null,
      numeroDocumento: null,
      ...pageFilterInitialValues,
      listaStatus: pageFilterInitialValues.listaStatusSelecionado || [],
    },
    validate: {
      dataInicial: (value, values: FilterData) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
        } else if (values.dataFinal) {
          return 'Campo obrigatório se data final estiver preenchida';
        }

        return null;
      },
      dataFinal: (value, values: FilterData) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
          if (values.dataInicial && value < values.dataInicial) {
            return 'Data final não pode ser anterior à data inicial';
          }
        } else if (values.dataInicial) {
          return 'Campo obrigatório se data inicial estiver preenchida';
        }
        return null;
      },
      idContrato: (value: number | undefined, values: FilterData) => {
        if (values.fonte === Fonte.Contrato && !value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      idEntidade: (value: number | undefined, values: FilterData) => {
        if (values.fonte === Fonte.Entidade && !value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      idProposta: (value: number | undefined, values: FilterData) => {
        if (values.fonte === Fonte.Proposta && !value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      numeroDocumento: (value: string | null, values: FilterData) => {
        if (values.idDocumentoTipo && (!value || value.trim() === '')) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const handleSubmit = async (values: FilterData, totalItems: number | null) => {
    if (form.validate().hasErrors) {
      return;
    }

    const formItem: FilterData = {
      ...values,
    };

    if (formItem.fonte === Fonte.Contrato) {
      formItem.idEntidade = undefined;
      formItem.idProposta = undefined;
    } else if (formItem.fonte === Fonte.Entidade) {
      formItem.idContrato = undefined;
      formItem.idProposta = undefined;
    } else if (formItem.fonte === Fonte.Proposta) {
      formItem.idContrato = undefined;
      formItem.idEntidade = undefined;
    } else {
      formItem.idContrato = undefined;
      formItem.idEntidade = undefined;
      formItem.idProposta = undefined;
    }

    if (!formItem.fonte) {
      delete (formItem as any).fonte;
    }

    props.filter(formItem, totalItems);
  };

  return (
    <form noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={1}>
            <Select
              icon={<Building size={15} />}
              label="Empresa"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.companies.map((x) => {
                return {
                  value: x.idEmpresa.toString(),
                  label: x.empresa,
                };
              })}
              searchable
              clearable={currentUser.executivo}
              disabled={!currentUser.executivo}
              {...form.getInputProps('idEmpresa')}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <MultiSelect
              label="Responsável"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.users.map((x) => {
                return {
                  label: x.idUsuario === currentUser.idUsuario ? `${x.nomeCompleto} (eu)` : x.nomeCompleto,
                  value: x.idUsuario,
                  group: x.empresa,
                };
              })}
              clearable
              searchable
              {...form.getInputProps('listaResponsavel')}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <div>
              <Input.Wrapper
                label="Data Inicial / Final"
                description="Filtro: entre"
                style={{ marginBottom: 5 }}
              >
                <div />
              </Input.Wrapper>
              <Group>
                <DatePicker
                  placeholder="Todas..."
                  icon={<Calendar size={15} />}
                  locale="pt-br"
                  inputFormat="DD/MM/YYYY"
                  clearable
                  allowFreeInput
                  {...form.getInputProps('dataInicial')}
                />
                <DatePicker
                  placeholder="Todas..."
                  icon={<Calendar size={15} />}
                  locale="pt-br"
                  inputFormat="DD/MM/YYYY"
                  clearable
                  allowFreeInput
                  minDate={form.values.dataInicial}
                  {...form.getInputProps('dataFinal')}
                />
              </Group>
            </div>
          </Grid.Col>
          <Grid.Col span={1}>
            <Select
              label="Fonte"
              description="Filtro: -"
              placeholder="Todos..."
              data={[
                { label: Fonte.Contrato, value: Fonte.Contrato },
                { label: Fonte.Entidade, value: Fonte.Entidade },
                { label: Fonte.Proposta, value: Fonte.Proposta },
              ]}
              clearable
              {...form.getInputProps('fonte')}
            />
          </Grid.Col>
          {form.values.fonte === Fonte.Contrato && (
            <Grid.Col span={1}>
              <NumberInput
                label="Contrato #"
                description="Filtro: igual"
                placeholder="Todos..."
                min={1}
                required
                {...form.getInputProps('idContrato')}
              />
            </Grid.Col>
          )}
          {form.values.fonte === Fonte.Entidade && (
            <Grid.Col span={1}>
              <NumberInput
                label="Entidade #"
                description="Filtro: igual"
                placeholder="Todos..."
                min={1}
                required
                {...form.getInputProps('idEntidade')}
              />
            </Grid.Col>
          )}
          {form.values.fonte === Fonte.Proposta && (
            <Grid.Col span={1}>
              <NumberInput
                label="Proposta #"
                description="Filtro: igual"
                placeholder="Todos..."
                min={1}
                required
                {...form.getInputProps('idProposta')}
              />
            </Grid.Col>
          )}
        </Grid>
        <Space h="md" />

        <Grid columns={6}>
          <Grid.Col span={2}>
            <MultiSelect
              label="Audiência"
              description="Filtro: igual"
              placeholder="Todos..."
              data={[
                { label: Audiencia.Comercial, value: AudienciaCodigo.Comercial },
                { label: Audiencia.Financeiro, value: AudienciaCodigo.Financeiro },
                { label: Audiencia.Operacional, value: AudienciaCodigo.Operacional },
              ]}
              clearable
              searchable
              {...form.getInputProps('listaAudiencia')}
              disabled={!!pageFilter}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <MultiSelect
              label="Tipo"
              description="Filtro: igual"
              placeholder="Todos..."
              data={
                pageFilter
                  ? pageFilterInitialValues.listaTipo!.map((x) => ({
                      label: TipoCodigo2[x],
                      value: x,
                    }))
                  : [
                      { label: Tipo.Cobranca, value: TipoCodigo.Cobranca },
                      { label: Tipo.Coleta, value: TipoCodigo.Coleta },
                      { label: Tipo.Comissao, value: TipoCodigo.Comissao },
                      { label: Tipo.Documetacao, value: TipoCodigo.Documetacao },
                      { label: Tipo.Expiracao, value: TipoCodigo.Expiracao },
                      { label: Tipo.Fechamento, value: TipoCodigo.Fechamento },
                      { label: Tipo.Lembrete, value: TipoCodigo.Lembrete },
                      { label: Tipo.MobilizacaoEnviar, value: TipoCodigo.MobilizacaoEnviar },
                      { label: Tipo.MobilizacaoRetirar, value: TipoCodigo.MobilizacaoRetirar },
                    ]
              }
              clearable
              searchable
              {...form.getInputProps('listaTipo')}
              // disabled={!!pageFilter}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <MultiSelect
              label="Status"
              description="Filtro: igual"
              placeholder="Todos..."
              data={
                pageFilter
                  ? pageFilterInitialValues.listaStatus!.map((x) => ({
                      label: StatusCodigo[x as Status],
                      value: x,
                    }))
                  : props.eventStatuses.map((x) => {
                      return {
                        value: x.codigoEventoStatus,
                        label: x.eventoStatus,
                      };
                    })
              }
              clearable
              searchable
              {...form.getInputProps('listaStatus')}
            />
          </Grid.Col>
        </Grid>
        <Space h="md" />

        <Grid columns={6}>
          <Grid.Col span={2}>
            <div>
              <Input.Wrapper label="Documento" description="Filtro: igual" style={{ marginBottom: 5 }}>
                <div />
              </Input.Wrapper>
              <Group>
                <Select
                  icon={<FileDots size={15} />}
                  placeholder="Todos..."
                  data={props.documents.map((x) => {
                    return {
                      value: x.idDocumentoTipo.toString(),
                      label: x.documentoTipo,
                    };
                  })}
                  searchable
                  clearable
                  {...form.getInputProps('idDocumentoTipo')}
                />
                <TextInput
                  icon={<Qrcode size={15} />}
                  placeholder="Número do documento"
                  maxLength={50}
                  {...form.getInputProps('numeroDocumento')}
                />
              </Group>
            </div>
          </Grid.Col>
          <Grid.Col span={1}>
            <NumberInput
              ref={refEventId}
              icon={<Id size={15} />}
              rightSection={
                <ActionIcon
                  size="sm"
                  radius="sm"
                  color="primary"
                  style={{ borderColor: theme?.colors?.primary?.[6], marginRight: 12 }}
                  variant="outline"
                  loading={props.loading}
                  onClick={() => {
                    const idEvento = Number(refEventId.current.value || 0);
                    if (idEvento && idEvento > 0) {
                      props.open(Number(idEvento));
                    }
                  }}
                >
                  <Search size={15} color={theme?.colors?.primary?.[6]} />
                </ActionIcon>
              }
              label="Evento"
              description="Filtro: igual"
              placeholder="Id"
              min={1}
              value={
                !Number.isNaN(Number(queryParams.get('id') || undefined))
                  ? Number(queryParams.get('id'))
                  : undefined
              }
              onKeyUp={(event: any) => {
                if (event.key === 'Enter') {
                  const idEvento = Number(event.target.value || 0);
                  if (idEvento && idEvento > 0) {
                    props.open(Number(idEvento));
                  }
                }
              }}
            />
          </Grid.Col>
        </Grid>
        <Space h="md" />

        <SimpleGrid cols={1}>
          <Group position="apart">
            <Group>
              <Button
                color="secondary"
                leftIcon={<ClearAll size={18} />}
                disabled={props.loading}
                onClick={() => {
                  form.reset();
                  form.setValues({
                    ...pageFilterInitialValues,
                    idEmpresa: currentUser.executivo ? undefined : form.values.idEmpresa,
                    listaResponsavel: undefined,
                    fonte: undefined,
                    idContrato: undefined,
                    idEntidade: undefined,
                    idProposta: undefined,
                    dataInicial: null as any,
                    dataFinal: null as any,
                    idDocumentoTipo: undefined,
                    numeroDocumento: '',
                    listaStatus: [],
                    listaTipo: [],
                  });

                  props.clear();
                }}
              >
                Limpar
              </Button>
              <Button
                leftIcon={<Search size={18} />}
                loading={props.loading}
                onClick={() => {
                  handleSubmit(form.values, null);
                }}
              >
                Pesquisar
              </Button>
            </Group>
            <Menu shadow="md" withArrow>
              <Menu.Target>
                <Button color="orange" leftIcon={<ZoomExclamation size={18} />} loading={props.loading}>
                  Pesquisar
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  key="250"
                  onClick={() => {
                    handleSubmit(form.values, 250);
                  }}
                >
                  250 registros
                </Menu.Item>
                <Menu.Item
                  key="500"
                  onClick={() => {
                    handleSubmit(form.values, 500);
                  }}
                >
                  500 registros
                </Menu.Item>
                <Menu.Item
                  key="1000"
                  onClick={() => {
                    handleSubmit(form.values, 1000);
                  }}
                >
                  1000 registros
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { Filter };
export type { FilterData };
