/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Alert, Button, Center, Group, Loader, Modal, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { AlertTriangle, CirclesRelation, Database, DeviceFloppy, Eye, Id } from 'tabler-icons-react';
import { ReferenciaCodigo, Relacao, Status } from '../../../../business/events/general';
import { useEventGeneralContext } from '../../../../contexts/core/events/EventGeneral.context';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import {
  CompanyType,
  ContractStatusType,
  DocumentType,
  EntityTypeType,
  EventRelationTypeData,
  EventStatusType,
  ProposalStatusType,
  UnitOfMeasureType,
} from '../../../../models/core/cache.type';
import { Permission } from '../../../../models/core/departments.type';
import {
  EventSearchResponseType,
  EventSharedResidueRequestType,
  EventSharedResidueType,
  EventType,
} from '../../../../models/core/events.type';
import { UserType } from '../../../../models/core/users.type';
import eventsService from '../../../../services/core/events.service';
import { Feature } from '../../../../utils/constants.utils';
import { buildFakeAuditObject } from '../../../../utils/helper.utils';
import { validate } from '../../../../utils/permission.utils';
import GeneralFormView from './components/GeneralFormView';
import ReferenceFormView from './components/ReferenceFormView';
import RelationFormView from './components/RelationFormView';
import SharedResidueView from './components/SharedResidueView/SharedResidueView';

type DataResult = {
  loading: boolean;
  eventData: EventType | null;
};

type ProposalFormViewProps = {
  referenceData: {
    companyData: CompanyType[];
    proposalStatusData: ProposalStatusType[];
    contractStatusData: ContractStatusType[];
    userData: UserType[];
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
    eventRelationTypeData: EventRelationTypeData[];
    eventStatusData: EventStatusType[];
    documentTypeData: DocumentType[];
  };
  idEvento: number | undefined;
  callback(
    items: EventSearchResponseType[] | null,
    action: string,
    value: string | null,
    confirmed: boolean
  ): void;
};

type ModalData = {
  opened: boolean;
  event: EventType | null;
  sharedResidues: EventSharedResidueType | null;
};

export default function EventFormAddEdit(props: ProposalFormViewProps) {
  const [dataResult, setDataResult] = useState<DataResult>({
    loading: true,
    eventData: null,
  });
  const [currentUser] = useCurrentUser();
  const [saving, setSaving] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({ opened: false, event: null, sharedResidues: null });
  const { eventGeneralData } = useEventGeneralContext();

  const refGeneralForm = useRef<any>();
  const refReferenceForm = useRef<any>();
  const refRelationForm = useRef<any>();

  // TODO: Event: 006 - handle 'personalizado' flag properly
  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: (
        <GeneralFormView
          ref={refGeneralForm}
          event={dataResult.eventData}
          referenceData={props.referenceData}
        />
      ),
      forceRender: true,
    },
    {
      key: 'Referencia',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Database size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Referência
          </Text>
        </Group>
      ),
      children: (
        <div>
          <ReferenceFormView
            ref={refReferenceForm}
            event={dataResult.eventData}
            referenceData={props.referenceData}
          />
        </div>
      ),
      disabled:
        !eventGeneralData.codigoEventoReferencia ||
        (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoServico &&
          !eventGeneralData.propostaServicoData) ||
        ((eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
          eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
          eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) &&
          !eventGeneralData.propostaResiduoData) ||
        ((eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
          eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
          eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) &&
          (!eventGeneralData.idEventoReferencia1 ||
            (!eventGeneralData.idEventoReferencia2 && !eventGeneralData.eventoReferenciaPersonalizado))),
      forceRender: true,
    },
    {
      key: 'Relacoes',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <CirclesRelation size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Relações
          </Text>
        </Group>
      ),
      children: (
        <RelationFormView
          ref={refRelationForm}
          event={dataResult.eventData}
          referenceData={props.referenceData}
        />
      ),
      forceRender: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      let eventData: any = null;
      try {
        if (props.idEvento) {
          eventData = await eventsService.select({ idEvento: Number(props.idEvento) });
          if (
            eventData.codigoEventoStatus === Status.Cancelado ||
            eventData.codigoEventoStatus === Status.Finalizado
          ) {
            showNotification({
              title: `Evento - Editar`,
              message: 'O evento não pode ser editado neste status.',
              color: 'red',
            });
            props.callback(null, 'callback', null, true);
          }
        }
      } catch (err: any) {
        showNotification({
          title: `Proposta - ${!props.idEvento ? 'Adicionar' : 'Editar'}`,
          message: err?.isBusinessException ? err.description : 'Não foi possível carregar o evento.',
          color: 'red',
        });
        props.callback(null, 'callback', null, true);
      } finally {
        setDataResult({
          loading: false,
          eventData,
        });
      }
    };

    if (!validate(!props.idEvento ? Permission.EventAdd : Permission.EventEdit, currentUser.permissoes)) {
      window.location.href = '/403';
      return;
    }

    fetchData();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const save = async (event: EventType, sharedResidues: EventSharedResidueRequestType[]) => {
    let response = null;
    try {
      setModalData({ ...modalData, opened: false });
      setSaving(true);
      if (!props.idEvento) {
        response = await eventsService.insert({
          evento: event,
          residuosCompartilhados: sharedResidues,
        });
        response.resumoJSON = response.resumoJSON ? JSON.parse(response.resumoJSON?.toString()) : null;
      } else {
        response = await eventsService.update({
          ...event,
          idEvento: props.idEvento,
        });
      }
      showNotification({
        title: `Evento - ${!props.idEvento ? 'Adicionar' : 'Editar'}`,
        message: `Evento #${response?.idEvento || props.idEvento} salvo com sucesso.`,
        color: 'green',
      });

      props.callback([JSON.parse(JSON.stringify(response))], 'callback', null, true);
    } catch (exception: any) {
      showNotification({
        title: `Evento - ${!props.idEvento ? 'Adicionar' : 'Editar'}`,
        message: exception?.isBusinessException ? exception.description : 'Não foi possível salvar o evento.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  const handleSubmit = async () => {
    let step = 'Geral';
    try {
      const generalData = refGeneralForm.current.validate();
      step = 'Referência';
      const referenceData = refReferenceForm.current.validate();

      step = 'Relações';
      const relacoes = refRelationForm.current.validate();

      const event = { ...generalData, ...referenceData, relacoes };

      if (generalData.tempCompartilhamento) {
        setSaving(true);
        const sharedResidues = await eventsService.searchSharedResidues({
          idPropostaResiduoPlano: generalData.idEventoReferencia2!,
        });
        setSaving(false);

        if (sharedResidues) {
          setModalData({ opened: true, event, sharedResidues });
        } else {
          await save(event, []);
        }
      } else {
        await save(event, []);
      }
    } catch (error: any) {
      setSaving(false);
      showNotification({
        title: `Evento - ${!props.idEvento ? 'Adicionar' : 'Editar'}`,
        message: `${step} - ${error.message}`,
        color: 'red',
      });
    }
  };

  return (
    <div>
      {dataResult.loading ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <div>
          <Modal
            opened={modalData.opened}
            closeOnClickOutside={false}
            closeOnEscape={false}
            size="60%"
            // centered={true}
            onClose={async () => {
              await save(modalData.event!, []);
              setModalData({ opened: false, event: null, sharedResidues: null });
            }}
            title="Criar eventos de coleta para outros resíduos que compartilham o mesmo serviço?"
          >
            <SharedResidueView
              sharedResidues={modalData.sharedResidues!}
              callback={async (data: any) => {
                await save(modalData.event!, data);
                setModalData({ opened: false, event: null, sharedResidues: null });
              }}
            />

            {/* <Group position="right">
              <Button
                // color={modalData?.action === 'cancelar' ? 'orange' : 'red'}
                // leftIcon={<Check size={18} />}
                color="primary"
                onClick={async () => {
                  await save(modalData.event!, modalData.sharedResidues);
                  setModalData({ opened: false, event: null, sharedResidues: null });
                }}
              >
                Continuar
              </Button>
            </Group> */}
          </Modal>

          <Paper shadow="xs" p="md" withBorder>
            {(dataResult?.eventData?.relacoes || [])?.findIndex(
              (x) => x.codigoEventoRelacao === Relacao.Substituicao
            ) > -1 && (
              <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
                Esse evento está relacionado à outro evento de substituição.
              </Alert>
            )}
            <Tabs items={tabs} defaultActiveKey="Geral" />
          </Paper>
          <Space h="lg" />

          <Group position="right">
            {props.idEvento && validate(Permission.EventView, currentUser.permissoes) && (
              <Button
                color="secondary"
                leftIcon={<Eye size={18} />}
                onClick={() =>
                  props.callback(
                    [{ ...buildFakeAuditObject(), ...dataResult.eventData }],
                    'visualizar',
                    null,
                    true
                  )
                }
                loading={saving}
                disabled={saving}
              >
                Visualizar
              </Button>
            )}
            <Button
              leftIcon={<DeviceFloppy size={18} />}
              onClick={handleSubmit}
              loading={saving}
              hidden={
                !validate(
                  !props.idEvento ? Permission.EventAdd : Permission.EventEdit,
                  currentUser.permissoes
                )
              }
            >
              Salvar
            </Button>
          </Group>
        </div>
      )}
    </div>
  );
}
