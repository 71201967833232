/* eslint-disable react/destructuring-assignment */
import { Alert, Button, Group, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { useRef, useState } from 'react';
import { CalendarEvent, CircleX, DeviceFloppy, Id, ZoomMoney } from 'tabler-icons-react';
import RecurrenceForm from '../../../../../../../components/core/Recurrence/RecurrenceForm';
import { ProposalGeneralData } from '../../../../../../../contexts/core/proposals/ProposalGeneral.context';
import { useProposalServiceContext } from '../../../../../../../contexts/core/proposals/ProposalService.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import { ProposalServiceType } from '../../../../../../../models/core/proposals.type';
import { EntityItemEnum, Feature } from '../../../../../../../utils/constants.utils';
import ServiceGeneralFormView from './components/ServiceGeneralFormView';
import ServiceQuotationFormView from './components/ServiceQuotationFormView';

type ProposalFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    entityTypeData: EntityTypeType[];
  };
  proposalGeneralData: ProposalGeneralData;
  origItem: ProposalServiceType | null;
  item: ProposalServiceType | null;
  idProposta: number | undefined;

  callback(item: ProposalServiceType | null, action: string, confirmed: boolean): void;
};

export default function ServiceFormAddEdit(props: ProposalFormViewProps) {
  const disabled = !!props.item?.idPropostaServicoPai;
  const { proposalServiceData } = useProposalServiceContext();

  const [error, setError] = useState<any | null>(null);

  const refGeneralForm = useRef<any>();
  const refRecorrenciaForm = useRef<any>();
  const refQuotationForm = useRef<any>();

  const handleSubmit = () => {
    let step = 'Geral';

    try {
      const generalData = refGeneralForm.current.validate();

      step = 'Recorrência';
      let recorrenciaData = refRecorrenciaForm?.current?.validate();

      if (!recorrenciaData) {
        recorrenciaData = {};
        recorrenciaData.action = Action.Nothing;

        if (generalData.action === Action.Add) {
          recorrenciaData.action = Action.Add;
        } else if (generalData.action === Action.Modify) {
          if (generalData.idResiduoAcondicionamento || generalData.idResiduoEquipamento) {
            recorrenciaData.action = Action.Modify;
          }
        }
      }
      recorrenciaData.idPropostaRecorrencia = props.origItem?.recorrencia.idPropostaRecorrencia;
      recorrenciaData.idProposta = props.origItem?.recorrencia.idProposta;
      recorrenciaData.idPropostaServico = props.origItem?.recorrencia.idPropostaServico;

      step = 'Cotações';
      const cotacoesData = refQuotationForm?.current?.validate() || {};
      props.callback(
        {
          ...generalData,
          recorrencia: recorrenciaData,
          cotacoes: cotacoesData,
        },
        'callback',
        true
      );
    } catch (ex: any) {
      setError({
        title: step,
        message: ex.message,
      });
    }
  };

  const buildTabs = () => {
    const tabs = [
      {
        key: 'Geral',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Id size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Geral
            </Text>
          </Group>
        ),
        children: (
          <ServiceGeneralFormView
            ref={refGeneralForm}
            proposalGeneralData={props.proposalGeneralData}
            callback={props.callback}
            idProposta={props.idProposta}
            item={props.item}
            origItem={props.origItem}
            referenceData={props.referenceData}
            disabled={disabled}
          />
        ),
        forceRender: true,
      },
    ];

    if (proposalServiceData.tipo === EntityItemEnum.Service) {
      tabs.push({
        key: 'Recorrencia',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <CalendarEvent size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Recorrência
            </Text>
          </Group>
        ),
        children: (
          <RecurrenceForm
            idPropostaRecorrencia={props.item?.recorrencia.idPropostaRecorrencia || null}
            title="Recorrência"
            description="Eventos personalizados a serem gerados para este serviço."
            color={Feature.Home.Proposal.color}
            minDate={props.proposalGeneralData.dataInicial}
            maxDate={props.proposalGeneralData.dataFinal}
            ref={refRecorrenciaForm}
            dataInicial={props.item?.recorrencia.dataInicial || proposalServiceData.dataInicial}
            dataFinal={props.item?.recorrencia.dataFinal || proposalServiceData.dataFinal}
            cada={props.item?.recorrencia.cada || null}
            frequencia={props.item?.recorrencia.frequencia || null}
            ocorrencia={props.item?.recorrencia.ocorrencia || null}
            diasSemana={props.item?.recorrencia.diasSemana || null}
            diasMes={props.item?.recorrencia.diasMes || null}
            mesAno={props.item?.recorrencia.mesAno || null}
            disabled={false}
          />
        ),
        forceRender: true,
      });
    }

    tabs.push({
      key: 'Cotacoes',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Cotações
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ServiceQuotationFormView
            ref={refQuotationForm}
            referenceData={props.referenceData}
            proposalService={props.item}
          />
        </Paper>
      ),
      forceRender: true,
    });

    return tabs;
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        {error && (
          <>
            <Alert
              icon={<CircleX size={18} />}
              title={error.title}
              color="red"
              withCloseButton
              onClose={() => setError(null)}
            >
              {error.message}
            </Alert>
            <Space h="xl" />
          </>
        )}

        <Tabs items={buildTabs()} defaultActiveKey="Geral" />
      </Paper>
      <Space h="lg" />

      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
