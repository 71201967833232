/* eslint-disable react/destructuring-assignment */
import { Alert, Button, Group, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { useRef, useState } from 'react';
import { CalendarEvent, Certificate, CircleX, DeviceFloppy, Id, Schema } from 'tabler-icons-react';
import RecurrenceForm from '../../../../../../../components/core/Recurrence/RecurrenceForm';
import { ProposalGeneralData } from '../../../../../../../contexts/core/proposals/ProposalGeneral.context';
import { useProposalResidueContext } from '../../../../../../../contexts/core/proposals/ProposalResidue.context';
import {
  EntityTypeType,
  ResidueStateOfMatterType,
  UnitOfMeasureType,
} from '../../../../../../../models/core/cache.type';
import { ProposalResidueType, ProposalServiceType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import ResidueDocumentFormView from './components/ResidueDocumentFormView';
import ResidueGeneralFormView from './components/ResidueGeneralFormView';
import ResiduePlanFormView from './components/ResiduePlanFormView';

type ProposalFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    entityTypeData: EntityTypeType[];
    residueStateOfMatterType: ResidueStateOfMatterType[];
  };
  proposalGeneralData: ProposalGeneralData;
  origItem: ProposalResidueType | null;
  item: ProposalResidueType | null;
  idProposta: number | undefined;
  services: ProposalServiceType[];

  callback(item: ProposalResidueType | null, action: string, confirmed: boolean): void;
};

export default function ResidueFormAddEdit(props: ProposalFormViewProps) {
  const disabled = !!props.item?.idPropostaResiduoPai;
  const { proposalResidueData } = useProposalResidueContext();

  const [error, setError] = useState<any | null>(null);

  const refGeneralForm = useRef<any>();
  const refDocumentForm = useRef<any>();
  const refRecurrenceForm = useRef<any>();
  const refPlanForm = useRef<any>();

  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: (
        <ResidueGeneralFormView
          ref={refGeneralForm}
          proposalGeneralData={props.proposalGeneralData}
          callback={props.callback}
          idProposta={props.idProposta}
          item={props.item}
          origItem={props.origItem}
          referenceData={props.referenceData}
          disabled={disabled}
        />
      ),
      forceRender: true,
    },
    {
      key: 'Documentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Certificate size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Documentos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResidueDocumentFormView ref={refDocumentForm} proposalResidue={props.item} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Recorrencia',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <CalendarEvent size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Recorrência
          </Text>
        </Group>
      ),
      children: (
        <RecurrenceForm
          idPropostaRecorrencia={props.item?.recorrencia.idPropostaRecorrencia || null}
          title="Recorrência"
          description="Eventos personalizados a serem gerados para este resíduo."
          color={Feature.Home.Proposal.color}
          minDate={props.proposalGeneralData.dataInicial}
          maxDate={props.proposalGeneralData.dataFinal}
          ref={refRecurrenceForm}
          dataInicial={props.item?.recorrencia.dataInicial || proposalResidueData.dataInicial}
          dataFinal={props.item?.recorrencia.dataFinal || proposalResidueData.dataFinal}
          cada={props.item?.recorrencia.cada || null}
          frequencia={props.item?.recorrencia.frequencia || null}
          ocorrencia={props.item?.recorrencia.ocorrencia || null}
          diasSemana={props.item?.recorrencia.diasSemana || null}
          diasMes={props.item?.recorrencia.diasMes || null}
          mesAno={props.item?.recorrencia.mesAno || null}
          disabled={false}
        />
      ),
      forceRender: true,
    },
    {
      key: 'Planos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Schema size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Planos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanFormView
            ref={refPlanForm}
            referenceData={props.referenceData}
            proposalResidue={props.item}
            services={props.services}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  const handleSubmit = () => {
    let step = 'Geral';
    try {
      const generalData = refGeneralForm.current.validate();

      step = 'Documentos';
      const documento = refDocumentForm.current.validate();

      step = 'Recorrência';
      const recorrenciaData = refRecurrenceForm?.current?.validate();
      recorrenciaData.idPropostaRecorrencia = props.origItem?.recorrencia.idPropostaRecorrencia;
      recorrenciaData.idProposta = props.origItem?.recorrencia.idProposta;
      recorrenciaData.idPropostaResiduo = props.origItem?.recorrencia.idPropostaResiduo;

      step = 'Planos';
      const planosData = refPlanForm?.current?.validate() || [];

      props.callback(
        {
          ...generalData,
          documento,
          recorrencia: recorrenciaData,
          planos: planosData,
        },
        'callback',
        true
      );
    } catch (ex: any) {
      setError({
        title: step,
        message: ex.message,
      });
    }
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        {error && (
          <>
            <Alert
              icon={<CircleX size={18} />}
              title={error.title}
              color="red"
              withCloseButton
              onClose={() => setError(null)}
            >
              {error.message}
            </Alert>
            <Space h="xl" />
          </>
        )}

        <Tabs items={tabs} defaultActiveKey="Geral" />
      </Paper>
      <Space h="lg" />

      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
