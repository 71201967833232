/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Badge,
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  SegmentedControl,
  SimpleGrid,
  Space,
  Switch,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { CurrencyReal, DeviceFloppy, Notes, Percentage } from 'tabler-icons-react';
import EntitySearch from '../../../../../../../components/core/EntitySearch/EntitySearch';
import { ProposalGeneralData } from '../../../../../../../contexts/core/proposals/ProposalGeneral.context';
import { ClosingType, EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import { EntitySearchResponseType } from '../../../../../../../models/core/entities.type';
import { ProposalCommissionType } from '../../../../../../../models/core/proposals.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  entidadeData: EntitySearchResponseType | null;
  idEntidade: number | null;
  entidade: string;

  tipo: string;
  porcentagem: number | null;
  preco: number | null;

  ocorrenciaFlag: boolean;
  ocorrencia: number | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    closingTypeData: ClosingType[];
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposalGeneralData: ProposalGeneralData;
  origItem: ProposalCommissionType | null;
  item: ProposalCommissionType | null;
  idProposta: number | undefined;
  callback(item: ProposalCommissionType | null, action: string, confirmed: boolean): void;
};

export default function CommissionFormAddEdit(props: FormViewProps) {
  const disabled = !!props.item?.idPropostaComissaoPai;

  const buildEntidadeDataFromItem = (
    item: ProposalCommissionType | null
  ): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idEntidade),
      tipos: [],
      cnpj: item.cnpj || null,
      razaoSocial: item.razaoSocial || null,
      nomeFantasia: item.nomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.cpf || null,
      nome: item.nome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      entidadeData: buildEntidadeDataFromItem(props.item),
      idEntidade: props.item?.idEntidade || null,
      entidade: props.item?.nomeFantasia || props.item?.razaoSocial || props.item?.nome || '',

      tipo: props.item?.preco ? '$' : '%',
      porcentagem: props.item?.porcentagem || 0,
      preco: props.item?.preco || 0,

      ocorrenciaFlag: !!props.item?.ocorrencia,
      ocorrencia: props.item?.ocorrencia || 1,

      observacao: props.item?.observacao || '',
    },
    validate: {
      entidade: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      porcentagem: (value: number | null, values: FormViewData) => {
        if (values.tipo === '%') {
          if (value === null || value === undefined || value?.toString().trim() === '') {
            return 'Campo obrigatório';
          }
          if (value <= 0) {
            return 'Valor deve ser maior que zero';
          }
        }

        return null;
      },
      preco: (value: number | null, values: FormViewData) => {
        if (values.tipo === '$') {
          if (value === null || value === undefined || value?.toString().trim() === '') {
            return 'Campo obrigatório';
          }
          if (value <= 0) {
            return 'Valor deve ser maior que zero';
          }
        }

        return null;
      },
      ocorrencia: (value: number | null, values: FormViewData) => {
        if (!value && values.ocorrenciaFlag) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idEntidade || ''}
    |${Number(props.origItem?.porcentagem) || ''}
    |${Number(props.origItem?.preco) || ''}
    |${Number(props.origItem?.ocorrencia) || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idEntidade || ''}
    |${Number(form.values.porcentagem) || ''}
    |${Number(form.values.preco) || ''}
    |${Number(form.values.ocorrencia) || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (form.validate().hasErrors) {
      return;
    }

    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: Action.Nothing,
        };
      }
    }

    const formItem: ProposalCommissionType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idProposta: props.idProposta || -1,
    };

    formItem.idEntidade = Number(form.values.entidadeData?.idEntidade);
    formItem.cnpj = form.values.entidadeData?.cnpj || null;
    formItem.razaoSocial = form.values.entidadeData?.razaoSocial || null;
    formItem.nomeFantasia = form.values.entidadeData?.nomeFantasia || null;
    formItem.cpf = form.values.entidadeData?.cpf || null;
    formItem.nome = form.values.entidadeData?.nome || null;

    formItem.porcentagem = form.values.tipo === '%' ? Number(formItem.porcentagem) : null;
    formItem.preco = form.values.tipo === '$' ? Number(formItem.preco) : null;

    if (!form.values.ocorrenciaFlag) {
      formItem.ocorrencia = null;
    }

    formItem.observacao = formItem.observacao?.trim() || null;

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <form id="proposal-commission" onSubmit={form.onSubmit(handleSubmit)} noValidate>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <EntitySearch
                label="Entidade"
                referenceData={props.referenceData}
                formItem={form.getInputProps('entidade') || undefined}
                idsToBeDisabled={[]}
                disabled={disabled}
                required
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('entidadeData', item);
                    form.setFieldValue('idEntidade', item.idEntidade);
                    form.setFieldValue('entidade', item.nomeFantasia || item.razaoSocial || item.nome || '');
                  }
                }}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={1}>
              <div>
                <Input.Wrapper label="Tipo" required>
                  <div />
                </Input.Wrapper>
                <SegmentedControl
                  color="primary"
                  data={[
                    { label: 'Porcentagem', value: '%' },
                    { label: 'Preço Fixo', value: '$' },
                  ]}
                  {...form.getInputProps('tipo')}
                />
              </div>
            </Grid.Col>
            <Grid.Col span={1}>
              {form.values.tipo === '%' ? (
                <NumberInput
                  icon={<Percentage size={15} />}
                  label="Porcentagem"
                  placeholder="Porcentagem"
                  min={0}
                  precision={2}
                  hideControls
                  {...form.getInputProps('porcentagem')}
                  required
                />
              ) : (
                <NumberInput
                  icon={<CurrencyReal size={15} />}
                  label="Preço"
                  placeholder="Preço"
                  min={0}
                  precision={2}
                  hideControls
                  {...form.getInputProps('preco')}
                  required
                />
              )}
            </Grid.Col>
            <Grid.Col span={2}>
              <div>
                <Input.Wrapper label="Encerrar após ocorrências de fechamento?" required>
                  <div />
                </Input.Wrapper>
                <Group>
                  <Switch
                    {...form.getInputProps('ocorrenciaFlag', { type: 'checkbox' })}
                    // disabled={disabled}
                  />
                  <NumberInput
                    style={{ width: 125 }}
                    placeholder="Ocorrências"
                    min={1}
                    defaultValue={1}
                    step={1}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    disabled={!form.values.ocorrenciaFlag}
                    required
                    {...form.getInputProps('ocorrencia')}
                  />
                  <Badge variant="outline" size="md">
                    {props.referenceData.closingTypeData.find(
                      (x) => x.codigoFechamento === props.proposalGeneralData.codigoFechamento
                    )?.fechamento || '-'}
                  </Badge>
                </Group>
              </div>
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre o a cotação do acondicionamento"
              autosize
              maxLength={500}
              // disabled={disabled}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </form>
      </Paper>
      <Space h="xl" />

      <Group position="right">
        <Button
          leftIcon={<DeviceFloppy size={18} />}
          type="button"
          form="proposal-commission"
          onClick={() => handleSubmit(form.values)}
        >
          Salvar
        </Button>
      </Group>
    </div>
  );
}
