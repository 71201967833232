/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import { useCallback, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { DeviceFloppy, MapPin, MapPins, Notes, Search } from 'tabler-icons-react';
import { ActionIcon, Button, Grid, Group, Paper, Select, SimpleGrid, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { EntityAddressContactType, EntityAddressType } from '../../../../../../../models/core/entities.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';
import { AddressTypeType } from '../../../../../../../models/core/cache.type';
import theme from '../../../../../../../theme';
import { procurarCEP, procurarMunicipios } from '../../../../../../../services/utils/brasilapi.service';
import { EstadoType, MunicipioType } from '../../../../../../../models/utils/brasilapi.type';
import AddressContactFormView from './AddressContactFormView';
import { Feature } from '../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../../models/core/core.type';

type FormViewData = {
  action: Action;
  id: string;
  codigoEnderecoTipo: string;
  enderecoTipo: string;
  codigoEstado: string;
  estado: string;
  cidade: string;
  cidadeCodigoIBGE: string;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string | null;
  referencia: string | null;
  cep: string;
  latitude: number | null;
  longitude: number | null;
  contatos: EntityAddressContactType[];
};

type FormViewProps = {
  referenceData: {
    addressTypeData: AddressTypeType[];
    estadoTypeData: EstadoType[];
  };
  origItem: EntityAddressType | null;
  item: (EntityAddressType & { origContacts?: EntityAddressContactType[] }) | null;
  idEntidade: number | undefined;
  callback(item: EntityAddressType | null, action: string, confirmed: boolean): void;
};

type MunicipioResult = {
  loading: boolean;
  municipioData: MunicipioType[];
};

export default function AddressFormAddEdit(props: FormViewProps) {
  const [searchingCEP, setSearchingCEP] = useState(false);
  const [enabledCEPFields, setEnabledCEPFields] = useState(!!props.item?.codigoEstado);
  const [municipioResult, setMunicipioResult] = useState<MunicipioResult>({
    loading: false,
    municipioData: [],
  });

  const refAddressContactForm = useRef<any>();

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),
      codigoEnderecoTipo: props.item?.codigoEnderecoTipo || '',
      enderecoTipo: props.item?.enderecoTipo || '',
      codigoEstado: props.item?.codigoEstado || '',
      estado: props.item?.estado || '',
      cidade: props.item?.cidade || '',
      cidadeCodigoIBGE: props.item?.cidadeCodigoIBGE?.toString() || '',
      bairro: props.item?.bairro || '',
      logradouro: props.item?.logradouro || '',
      numero: props.item?.numero || '',
      complemento: props.item?.complemento || '',
      referencia: props.item?.referencia || '',
      cep: props.item?.cep || '',
      latitude: props.item?.latitude || null,
      longitude: props.item?.longitude || null,
      contatos: [],
    },
    validate: {
      codigoEnderecoTipo: (value) => {
        if (value === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      cep: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        if (!/^\d{5}-\d{3}$/.test(value || '')) {
          return 'Formato inválido (Ex: 00000-00)';
        }
        return null;
      },
      codigoEstado: (value) => {
        if (value === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      cidadeCodigoIBGE: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      bairro: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      logradouro: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      numero: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchCEP = async () => {
    if (!form.validateField('cep').hasError) {
      const cep = form.values.cep || '';
      setSearchingCEP(true);
      try {
        const cepData = await procurarCEP(cep.replace(/\D+/g, ''));
        form.values.codigoEstado = cepData.state;
        await setCidade(cepData.state, cepData.city);
        form.values.bairro = cepData.neighborhood;
        form.values.logradouro = cepData.street;
        form.values.latitude = Number(cepData.location.coordinates.latitude) || null;
        form.values.longitude = Number(cepData.location.coordinates.longitude) || null;

        form.validateField('codigoEstado');
        form.validateField('cidadeCodigoIBGE');
        form.validateField('bairro');
        form.validateField('logradouro');
      } catch (error: any) {
        form.values.codigoEstado = '';
        form.values.cidadeCodigoIBGE = '';
        form.values.cidade = '';
        form.values.bairro = '';
        form.values.logradouro = '';
        form.values.latitude = null;
        form.values.longitude = null;
        showNotification({
          title: `Entidade - ${!props.item?.idEntidadeEndereco ? 'Adicionar' : 'Editar'}`,
          message: 'CEP não encontrado.',
          color: 'red',
        });
      } finally {
        setSearchingCEP(false);
        setEnabledCEPFields(true);
      }
    }
  };

  const setCidade = useCallback(
    async (codigoEstado: string, cidade: string) => {
      let tempMunicipioData: MunicipioType[] = [];
      try {
        setMunicipioResult({ loading: true, municipioData: tempMunicipioData });

        tempMunicipioData = await procurarMunicipios(codigoEstado);

        const municipioItem = tempMunicipioData.find(
          (x) => x.nome.localeCompare(cidade, 'pt-BR', { sensitivity: 'base' }) === 0
        );
        if (municipioItem) {
          form.values.cidadeCodigoIBGE = municipioItem.codigo_ibge || '';
          form.values.cidade = municipioItem.nome;
        } else {
          form.values.cidadeCodigoIBGE = '';
          form.values.cidade = '';
        }
      } catch (error: any) {
      } finally {
        setMunicipioResult({ loading: false, municipioData: tempMunicipioData });
      }
    },
    [form.values]
  );

  const isModified = (): boolean => {
    const origData = `${props.origItem?.codigoEnderecoTipo || ''}
    |${props.origItem?.codigoEstado || ''}
    |${props.origItem?.cidade || ''}
    |${props.origItem?.cidadeCodigoIBGE || ''}
    |${props.origItem?.bairro || ''}
    |${props.origItem?.logradouro || ''}
    |${props.origItem?.numero || ''}
    |${props.origItem?.complemento || ''}
    |${props.origItem?.referencia || ''}
    |${props.origItem?.cep || ''}
    |${props.origItem?.latitude || ''}
    |${props.origItem?.longitude || ''}`;

    const formData = `${form.values.codigoEnderecoTipo || ''}
    |${form.values.codigoEstado || ''}
    |${form.values.cidade || ''}
    |${form.values.cidadeCodigoIBGE || ''}
    |${form.values.bairro || ''}
    |${form.values.logradouro || ''}
    |${form.values.numero || ''}
    |${form.values.complemento || ''}
    |${form.values.referencia || ''}
    |${form.values.cep || ''}
    |${form.values.latitude || ''}
    |${form.values.longitude || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: Action.Nothing,
          cidadeCodigoIBGE: values.cidadeCodigoIBGE,
        };
      }
    }

    values.enderecoTipo =
      props.referenceData.addressTypeData.find((x) => x.codigoEnderecoTipo === values.codigoEnderecoTipo)
        ?.enderecoTipo || '';

    values.estado =
      props.referenceData.estadoTypeData.find((x) => x.sigla === values.codigoEstado)?.nome || '';

    values.cidade =
      municipioResult.municipioData.find((x) => x.codigo_ibge === values.cidadeCodigoIBGE)?.nome || '';

    const formItem: EntityAddressType = {
      ...values,
      ...buildFakeAuditObject(),
      cidadeCodigoIBGE: Number(values.cidadeCodigoIBGE) || null,
      idEntidade: props.idEntidade || -1,
    };

    formItem.complemento = formItem.complemento?.trim() || null;
    formItem.referencia = formItem.referencia?.trim() || null;
    formItem.contatos = refAddressContactForm.current.validate();

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  useEffect(() => {
    const fetchData = async () => {
      let tempMunicipioData: MunicipioType[] = [];
      try {
        setMunicipioResult({ loading: true, municipioData: tempMunicipioData });
        tempMunicipioData = await procurarMunicipios(form.values.codigoEstado);
      } catch (error: any) {
      } finally {
        setMunicipioResult({ loading: false, municipioData: tempMunicipioData });
      }
    };
    if (form.values.codigoEstado) {
      fetchData();
    }
  }, []);

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <form id="entity-address" onSubmit={form.onSubmit(handleSubmit)} noValidate>
          <PageSection size="lg" color={Feature.Reference.Entity.color} label="Endereço" text="" />
          <Space h="xs" />
          <SimpleGrid cols={2}>
            <Select
              icon={<MapPins size={15} />}
              label="Tipo"
              placeholder="Selecione..."
              data={props.referenceData.addressTypeData.map((x) => {
                return {
                  value: x.codigoEnderecoTipo,
                  label: x.enderecoTipo,
                };
              })}
              required
              {...form.getInputProps('codigoEnderecoTipo')}
            />
            <NumberFormat
              icon={<MapPin size={15} />}
              rightSection={
                <ActionIcon
                  size="sm"
                  radius="sm"
                  color="primary"
                  style={{ borderColor: theme?.colors?.primary?.[6] }}
                  variant="outline"
                  loading={searchingCEP}
                  onClick={() => {
                    searchCEP();
                  }}
                >
                  <Search size={15} color={theme?.colors?.primary?.[6]} />
                </ActionIcon>
              }
              label="CEP"
              placeholder="_____-___"
              disabled={searchingCEP}
              required
              onKeyUp={(event: any) => {
                if (event.key === 'Enter') {
                  searchCEP();
                }
              }}
              mask="_"
              format="#####-###"
              customInput={TextInput}
              {...form.getInputProps('cep')}
            />
          </SimpleGrid>
          <Space h="xs" />

          <Grid columns={3}>
            <Grid.Col span={2}>
              <TextInput
                icon={<MapPin size={15} />}
                label="Logradouro"
                placeholder="Digite o logradouro"
                maxLength={100}
                required
                disabled={!enabledCEPFields}
                {...form.getInputProps('logradouro')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                icon={<MapPin size={15} />}
                label="Número"
                placeholder="Digite o número"
                maxLength={50}
                required
                {...form.getInputProps('numero')}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={3}>
            <Select
              icon={<MapPin size={15} />}
              label="Estado"
              placeholder="Selecione..."
              data={props.referenceData.estadoTypeData.map((x) => {
                return {
                  value: x.sigla,
                  label: x.nome,
                  group: x.regiao.nome,
                };
              })}
              searchable
              required
              disabled={!enabledCEPFields}
              value={form.values.codigoEstado}
              onChange={async (codigoEstado: string) => {
                await setCidade(codigoEstado, '');
                form.setFieldValue('codigoEstado', codigoEstado);
              }}
              error={form.getInputProps('codigoEstado').error}
            />
            <Select
              icon={<MapPin size={15} />}
              label="Cidade"
              placeholder="Selecione..."
              data={municipioResult.municipioData.map((x) => {
                return {
                  value: x.codigo_ibge,
                  label: x.nome,
                };
              })}
              disabled={
                searchingCEP || municipioResult.loading || !form.values.codigoEstado || !enabledCEPFields
              }
              searchable
              required
              {...form.getInputProps('cidadeCodigoIBGE')}
            />
            <TextInput
              icon={<MapPin size={15} />}
              label="Bairro"
              placeholder="Digite o bairro"
              maxLength={50}
              required
              disabled={!enabledCEPFields}
              {...form.getInputProps('bairro')}
            />
          </SimpleGrid>
          <Space h="xs" />

          <SimpleGrid cols={2}>
            <TextInput
              icon={<Notes size={15} />}
              label="Complemento"
              placeholder="Digite o complemento"
              maxLength={50}
              {...form.getInputProps('complemento')}
            />
            <TextInput
              icon={<Notes size={15} />}
              label="Referência"
              placeholder="Digite a referência"
              maxLength={50}
              {...form.getInputProps('referencia')}
            />
          </SimpleGrid>
        </form>
      </Paper>
      <Space h="lg" />
      <Paper shadow="xs" p="md" withBorder>
        <AddressContactFormView ref={refAddressContactForm} entityAddress={props.item} />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit" form="entity-address">
          Salvar
        </Button>
      </Group>
    </div>
  );
}
