/* eslint-disable no-nested-ternary */
/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
import { Paper, Space, Table } from '@mantine/core';
import { ReactNode } from 'react';
import { calcularPreco } from '../../../../../../business/proposals/estimate';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import {
  ProposalResiduePlanPackagingType,
  ProposalResiduePlanQuotationEquipmentType,
  ProposalResiduePlanQuotationPackagingType,
  ProposalType,
} from '../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../utils/formatter.utils';

type DataViewProps = {
  data: ProposalType;
};

export default function SummaryView(props: DataViewProps) {
  const buildResidues = () => {
    const buildPackagingAndEquipment = () => {
      let items: ReactNode[] = [];

      for (const residue of props.data.residuos) {
        const plan = residue.planos.find((x) => x.primario);
        let quotations: any;

        quotations = plan?.cotacoes
          .filter((x) => x.primario)
          .map((y) => y.acondicionamentos)
          .flat();
        items = items.concat(
          plan?.acondicionamentos.map((y: ProposalResiduePlanPackagingType) => {
            return (
              <tr key={`bpae_${residue.idPropostaResiduo}.${y.idPropostaResiduoPlanoAcondicionamento}`}>
                <td>{`${residue.idPropostaResiduo}.${y.idPropostaResiduoPlanoAcondicionamento}`}</td>
                <td>{residue.residuoCliente}</td>
                <td>{y.residuoAcondicionamento}</td>
                <td>{y.gerenciar ? 'Sim' : 'Não'}</td>
                <td>{y.compartilhado ? 'Sim' : 'Não'}</td>
                <td>
                  {quotations
                    ?.filter(
                      (q: ProposalResiduePlanQuotationPackagingType) =>
                        q.idPropostaResiduoPlanoAcondicionamento === y.idPropostaResiduoPlanoAcondicionamento
                    )
                    .map((x: ProposalResiduePlanQuotationPackagingType) => {
                      // TODO: FIX ME - get 'quantidade' from the service and NOT from the quotation
                      return `${y.compartilhado ? `% de ${x.quantidade}` : x.quantidade} ${
                        x.quantidadeUnidadeMedida
                      }`;
                    })?.[0] || '-'}
                </td>
              </tr>
            );
          }) || []
        );

        quotations = plan?.cotacoes
          .filter((x) => x.primario)
          .map((y) => y.equipamentos)
          .flat();
        items = items.concat(
          plan?.equipamentos.map((y) => {
            return (
              <tr key={`bpae_${residue.idPropostaResiduo}.${y.idPropostaResiduoPlanoEquipamento}`}>
                <td>{`${residue.idPropostaResiduo}.${y.idPropostaResiduoPlanoEquipamento}`}</td>
                <td>{residue.residuoCliente}</td>
                <td>{y.residuoEquipamento}</td>
                <td>-</td>
                <td>-</td>
                <td>
                  {quotations
                    ?.filter(
                      (q: ProposalResiduePlanQuotationEquipmentType) =>
                        q.idPropostaResiduoPlanoEquipamento === y.idPropostaResiduoPlanoEquipamento
                    )
                    .map((x: ProposalResiduePlanQuotationEquipmentType) => {
                      return `${x.quantidade} ${x.quantidadeUnidadeMedida}`;
                    })?.[0] || '-'}
                </td>
              </tr>
            );
          }) || []
        );
      }

      return (
        <>
          <PageSection
            size="md"
            color={Feature.Home.Proposal.color}
            label="Acondicionamento e Equipamento"
            text="Serão disponibilizados os acondicionamentos e equipamentos abaixo:"
          />
          <Space h="lg" />

          <Table withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Ref.:</th>
                <th>Resíduo</th>
                <th>Item</th>
                <th>Gerenciar?</th>
                <th>Compartilhado?</th>
                <th>Quantidade</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </Table>
        </>
      );
    };

    const buildVehicle = () => {
      let items: ReactNode[] = [];

      for (const residue of props.data.residuos) {
        const plan = residue.planos.find((x) => x.primario);

        items = items.concat(
          plan?.veiculo
            ? [
                <tr key={`bv_${residue.idPropostaResiduo}.${plan.veiculo?.idPropostaResiduoPlanoVeiculo}`}>
                  <td>{`${residue.idPropostaResiduo}.${plan.veiculo?.idPropostaResiduoPlanoVeiculo}`}</td>
                  <td>{residue.residuoCliente}</td>
                  <td>{plan.veiculo.residuoVeiculo}</td>
                </tr>,
              ]
            : []
        );
      }

      return (
        <>
          <PageSection
            size="md"
            color={Feature.Home.Proposal.color}
            label="Veículo"
            text="Serão utilizados os veículos abaixo:"
          />
          <Space h="lg" />

          <Table withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Ref.:</th>
                <th>Resíduo</th>
                <th>Item</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </Table>
        </>
      );
    };

    const buildTreatmentAndDestination = () => {
      let items: ReactNode[] = [];

      for (const residue of props.data.residuos) {
        const plan = residue.planos.find((x) => x.primario);

        items = items.concat(
          plan?.tratamento
            ? [
                <tr
                  key={`btad_${residue.idPropostaResiduo}.${plan.tratamento?.idPropostaResiduoPlanoTratamento}`}
                >
                  <td>{`${residue.idPropostaResiduo}.${plan.tratamento?.idPropostaResiduoPlanoTratamento}`}</td>
                  <td>{residue.residuoCliente}</td>
                  <td>{plan.tratamento.residuoTratamento}</td>
                </tr>,
              ]
            : []
        );

        items = items.concat(
          plan?.destinoFinal
            ? [
                <tr
                  key={`btad_${residue.idPropostaResiduo}.${plan.destinoFinal?.idPropostaResiduoPlanoDestinoFinal}`}
                >
                  <td>{`${residue.idPropostaResiduo}.${plan.destinoFinal?.idPropostaResiduoPlanoDestinoFinal}`}</td>
                  <td>{residue.residuoCliente}</td>
                  <td>{plan.destinoFinal.residuoDestinoFinal}</td>
                </tr>,
              ]
            : []
        );
      }

      return (
        <>
          <PageSection
            size="md"
            color={Feature.Home.Proposal.color}
            label="Tratamento e Destinação"
            text="Serão realizados os tratamentos e destinações abaixo:"
          />
          <Space h="lg" />

          <Table withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Ref.:</th>
                <th>Resíduo</th>
                <th>Item</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </Table>
        </>
      );
    };

    const buildPrice = () => {
      let items: ReactNode[] = [];
      let quotations: any;

      for (const residue of props.data.residuos) {
        const plan = residue.planos.find((x) => x.primario);

        quotations = plan?.cotacoes
          .filter((x) => x.primario)
          .map((y) => y.acondicionamentos)
          .filter((yy) => yy)
          .flat()
          .map((z) => {
            return {
              ...z,
              calcPreco: formatCurrency(
                calcularPreco(z.preco, z.preco, z.margem, z.precoFinal, z.imposto || 0, residue.compra, false)
                  .novoPrecoComMargem
              ),
              calcQuantidade: `${z.quantidade} ${z.quantidadeUnidadeMedida} / ${z.frequenciaUnidadeMedida}`,
            };
          });

        items = items.concat(
          plan?.acondicionamentos.map((y) => {
            return (
              <tr key={`bpa_${residue.idPropostaResiduo}.${y.idPropostaResiduoPlanoAcondicionamento}`}>
                <td>{`${residue.idPropostaResiduo}.${y.idPropostaResiduoPlanoAcondicionamento}`}</td>
                <td>{residue.residuoCliente}</td>
                <td>Locação</td>
                <td>{y.residuoAcondicionamento}</td>
                <td>{y.compartilhado ? '%' : `${y.quantidade} ${y.quantidadeUnidadeMedida}`}</td>
                <td>
                  {quotations?.find(
                    (q: any) =>
                      q.idPropostaResiduoPlanoAcondicionamento === y.idPropostaResiduoPlanoAcondicionamento
                  )?.calcPreco || '-'}
                </td>
                <td>
                  {quotations?.find(
                    (q: any) =>
                      q.idPropostaResiduoPlanoAcondicionamento === y.idPropostaResiduoPlanoAcondicionamento
                  )?.calcQuantidade || '-'}
                </td>
                <td>
                  {quotations?.find(
                    (q: any) =>
                      q.idPropostaResiduoPlanoAcondicionamento === y.idPropostaResiduoPlanoAcondicionamento
                  )?.observacao || '-'}
                </td>
              </tr>
            );
          }) || []
        );

        quotations = plan?.cotacoes
          .filter((x) => x.primario)
          .map((y) => y.equipamentos)
          .filter((yy) => yy)
          .flat()
          .map((z) => {
            return {
              ...z,
              calcPreco: formatCurrency(
                calcularPreco(z.preco, z.preco, z.margem, z.precoFinal, z.imposto || 0, residue.compra, false)
                  .novoPrecoComMargem
              ),
              calcQuantidade: `${z.quantidade} ${z.quantidadeUnidadeMedida} / ${z.frequenciaUnidadeMedida}`,
            };
          });
        items = items.concat(
          plan?.equipamentos.map((y) => {
            return (
              <tr key={`bpe_${residue.idPropostaResiduo}.${y.idPropostaResiduoPlanoEquipamento}`}>
                <td>{`${residue.idPropostaResiduo}.${y.idPropostaResiduoPlanoEquipamento}`}</td>
                <td>{residue.residuoCliente}</td>
                <td>Locação</td>
                <td>{y.residuoEquipamento}</td>
                <td>{`${y.quantidade} ${y.quantidadeUnidadeMedida}`}</td>
                <td>
                  {quotations?.find(
                    (q: any) => q.idPropostaResiduoPlanoEquipamento === y.idPropostaResiduoPlanoEquipamento
                  )?.calcPreco || '-'}
                </td>
                <td>
                  {quotations?.find(
                    (q: any) => q.idPropostaResiduoPlanoEquipamento === y.idPropostaResiduoPlanoEquipamento
                  )?.calcQuantidade || '-'}
                </td>
                <td>
                  {quotations?.find(
                    (q: any) => q.idPropostaResiduoPlanoEquipamento === y.idPropostaResiduoPlanoEquipamento
                  )?.observacao || '-'}
                </td>
              </tr>
            );
          }) || []
        );

        quotations = plan?.cotacoes
          .filter((x) => x.primario)
          .map((y) => y.veiculo)
          .filter((yy) => yy)
          .flat()
          .map((z) => {
            return {
              ...z,
              calcPreco: formatCurrency(
                calcularPreco(z.preco, z.preco, z.margem, z.precoFinal, z.imposto || 0, residue.compra, false)
                  .novoPrecoComMargem
              ),
              calcQuantidade: `${z.quantidade} ${z.quantidadeUnidadeMedida} / ${z.frequenciaUnidadeMedida}`,
            };
          });
        items = items.concat(
          plan?.veiculo
            ? [
                <tr key={`bpv_${residue.idPropostaResiduo}.${plan.veiculo.idPropostaResiduoPlanoVeiculo}`}>
                  <td>{`${residue.idPropostaResiduo}.${plan.veiculo.idPropostaResiduoPlanoVeiculo}`}</td>
                  <td>{residue.residuoCliente}</td>
                  <td>Transporte</td>
                  <td>{plan.veiculo.residuoVeiculo}</td>
                  <td>{`${plan.veiculo.quantidade} ${plan.veiculo.quantidadeUnidadeMedida}`}</td>
                  <td>
                    {quotations?.find(
                      (q: any) =>
                        q.idPropostaResiduoPlanoVeiculo === plan.veiculo!.idPropostaResiduoPlanoVeiculo
                    )?.calcPreco || '-'}
                  </td>
                  <td>
                    {quotations?.find(
                      (q: any) =>
                        q.idPropostaResiduoPlanoVeiculo === plan.veiculo!.idPropostaResiduoPlanoVeiculo
                    )?.calcQuantidade || '-'}
                  </td>
                  <td>
                    {quotations?.find(
                      (q: any) =>
                        q.idPropostaResiduoPlanoVeiculo === plan.veiculo!.idPropostaResiduoPlanoVeiculo
                    )?.observacao || '-'}
                  </td>
                </tr>,
              ]
            : []
        );

        quotations = plan?.cotacoes
          .filter((x) => x.primario)
          .map((y) => y.tratamento)
          .filter((yy) => yy)
          .flat()
          .map((z) => {
            return {
              ...z,
              calcPreco: formatCurrency(
                calcularPreco(z.preco, z.preco, z.margem, z.precoFinal, z.imposto || 0, residue.compra, false)
                  .novoPrecoComMargem
              ),
              calcQuantidade: `${z.quantidade} ${z.quantidadeUnidadeMedida}`,
            };
          });
        items = items.concat(
          plan?.tratamento
            ? [
                <tr
                  key={`bpt_${residue.idPropostaResiduo}.${plan.tratamento.idPropostaResiduoPlanoTratamento}`}
                >
                  <td>{`${residue.idPropostaResiduo}.${plan.tratamento.idPropostaResiduoPlanoTratamento}`}</td>
                  <td>{residue.residuoCliente}</td>
                  <td>Destinação</td>
                  <td>{plan.tratamento.residuoTratamento}</td>
                  <td>-</td>
                  <td>
                    {quotations?.find(
                      (q: any) =>
                        q.idPropostaResiduoPlanoTratamento ===
                        plan.tratamento!.idPropostaResiduoPlanoTratamento
                    )?.calcPreco || '-'}
                  </td>
                  <td>
                    {quotations?.find(
                      (q: any) =>
                        q.idPropostaResiduoPlanoTratamento ===
                        plan.tratamento!.idPropostaResiduoPlanoTratamento
                    )?.calcQuantidade || '-'}
                  </td>
                  <td>
                    {quotations?.find(
                      (q: any) =>
                        q.idPropostaResiduoPlanoTratamento ===
                        plan.tratamento!.idPropostaResiduoPlanoTratamento
                    )?.observacao || '-'}
                  </td>
                </tr>,
              ]
            : []
        );

        quotations = plan?.cotacoes
          .filter((x) => x.primario)
          .map((y) => y.destinoFinal)
          .filter((yy) => yy)
          .flat()
          .map((z) => {
            return {
              ...z,
              calcReceita: z.receita,
              calcPreco: formatCurrency(
                z.margem === null && z.precoFinal === null
                  ? z.preco
                  : z.receita
                  ? calcularPreco(
                      z.preco,
                      z.preco,
                      z.margem,
                      z.precoFinal,
                      z.imposto || 0,
                      residue.compra,
                      z.receita
                    ).preco
                  : calcularPreco(
                      z.preco,
                      z.preco,
                      z.margem,
                      z.precoFinal,
                      z.imposto || 0,
                      residue.compra,
                      z.receita
                    ).novoPrecoComMargem
              ),
              calcQuantidade: `${z.quantidade} ${z.quantidadeUnidadeMedida}`,
            };
          });

        items = items.concat(
          plan?.destinoFinal
            ? [
                <tr
                  key={`bpd_${residue.idPropostaResiduo}.${plan.destinoFinal.idPropostaResiduoPlanoDestinoFinal}`}
                >
                  <td>{`${residue.idPropostaResiduo}.${plan.destinoFinal.idPropostaResiduoPlanoDestinoFinal}`}</td>
                  <td>{residue.residuoCliente}</td>
                  <td>Destinação</td>
                  <td>{plan.destinoFinal.residuoDestinoFinal}</td>
                  <td>-</td>
                  <td>
                    {`${
                      quotations?.find(
                        (q: any) =>
                          q.idPropostaResiduoPlanoDestinoFinal ===
                          plan.destinoFinal!.idPropostaResiduoPlanoDestinoFinal
                      )?.calcPreco || '-'
                    }${
                      quotations?.find(
                        (q: any) =>
                          q.idPropostaResiduoPlanoDestinoFinal ===
                          plan.destinoFinal!.idPropostaResiduoPlanoDestinoFinal
                      )?.calcReceita
                        ? ' (Compra)'
                        : ''
                    }`}
                  </td>
                  <td>
                    {quotations?.find(
                      (q: any) =>
                        q.idPropostaResiduoPlanoDestinoFinal ===
                        plan.destinoFinal!.idPropostaResiduoPlanoDestinoFinal
                    )?.calcQuantidade || '-'}
                  </td>
                  <td>
                    {quotations?.find(
                      (q: any) =>
                        q.idPropostaResiduoPlanoDestinoFinal ===
                        plan.destinoFinal!.idPropostaResiduoPlanoDestinoFinal
                    )?.observacao || '-'}
                  </td>
                </tr>,
              ]
            : []
        );
      }

      return (
        <>
          <PageSection
            size="md"
            color={Feature.Home.Proposal.color}
            label="Valores"
            text="Valores sobre cada item abaixo:"
          />
          <Space h="lg" />

          <Table withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Ref.:</th>
                <th>Resíduo</th>
                <th>Tipo de Serviço</th>
                <th>Serviço</th>
                <th>Quantidade</th>
                <th>Valor</th>
                <th>Unidade</th>
                <th>Obs</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </Table>
        </>
      );
    };

    return (
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Home.Proposal.color} label="Resíduos" text="" />
        <Space h="xs" />

        {buildPackagingAndEquipment()}
        <Space h="lg" />

        {buildVehicle()}
        <Space h="lg" />

        {buildTreatmentAndDestination()}
        <Space h="lg" />

        {buildPrice()}
      </Paper>
    );
  };

  const buildServices = () => {
    const buildPackagingAndEquipment = () => {
      let items: ReactNode[] = [];

      for (const service of props.data.servicos.filter(
        (x) => x.idResiduoAcondicionamento !== null || x.idResiduoEquipamento !== null
      )) {
        const quotation = service.cotacoes.find((x) => x.primario);

        items = items.concat(
          quotation
            ? [
                <tr key={`sbpae_${service.idPropostaServico}`}>
                  <td>{`${service.idPropostaServico}`}</td>
                  <td>{service.servico}</td>
                  <td>{service.residuoAcondicionamento || service.residuoEquipamento}</td>
                  <td>{!service.idResiduoAcondicionamento ? '-' : service.gerenciar ? 'Sim' : 'Não'}</td>
                  <td>{!service.idResiduoAcondicionamento ? '-' : service.compartilhado ? 'Sim' : 'Não'}</td>
                  <td>{service.quantidade}</td>
                  <td>
                    {`${formatCurrency(
                      calcularPreco(
                        quotation.preco,
                        quotation.preco,
                        quotation.margem,
                        quotation.precoFinal,
                        quotation.imposto || 0,
                        false,
                        false
                      ).novoPrecoComMargem
                    )}`}
                  </td>
                  <td>{`${quotation.quantidade} ${quotation.frequenciaUnidadeMedida1} / ${quotation.frequenciaUnidadeMedida2}`}</td>
                </tr>,
              ]
            : []
        );
      }

      return (
        <>
          <PageSection
            size="md"
            color={Feature.Home.Proposal.color}
            label="Acondicionamento e Equipamento"
            text="Serão disponibilizados os acondicionamentos e equipamentos abaixo:"
          />
          <Space h="lg" />

          <Table withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Ref.:</th>
                <th>Serviço</th>
                <th>Item</th>
                <th>Gerenciar?</th>
                <th>Compartilhado?</th>
                <th>Quantidade</th>
                <th>Valor</th>
                <th>Unidade</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </Table>
        </>
      );
    };

    const buildSupplier = () => {
      let items: ReactNode[] = [];

      for (const service of props.data.servicos.filter(
        (x) => x.idResiduoAcondicionamento === null && x.idResiduoEquipamento === null
      )) {
        const quotation = service.cotacoes.find((x) => x.primario);

        items = items.concat(
          quotation
            ? [
                <tr key={`sbs_${service.idPropostaServico}`}>
                  <td>{`${service.idPropostaServico}`}</td>
                  <td>{service.servico}</td>
                  <td>{service.jornada || '-'}</td>
                  <td>{`${service.quantidade}`}</td>
                  <td>
                    {formatCurrency(
                      calcularPreco(
                        quotation.preco,
                        quotation.preco,
                        quotation.margem,
                        quotation.precoFinal,
                        quotation.imposto || 0,
                        false,
                        false
                      ).novoPrecoComMargem
                    )}
                  </td>
                  <td>
                    {`${quotation.quantidade} - ${quotation.frequencia} ${quotation.frequenciaUnidadeMedida1} / ${quotation.frequenciaUnidadeMedida2}`}
                  </td>
                  <td>{quotation.observacao || '-'}</td>
                </tr>,
              ]
            : []
        );
      }

      return (
        <>
          <PageSection
            size="md"
            color={Feature.Home.Proposal.color}
            label="Profissional"
            text="Serão disponibilizados os profissionais abaixo:"
          />
          <Space h="lg" />

          <Table withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Ref.:</th>
                <th>Serviço</th>
                <th>Jornada</th>
                <th>Quantidade</th>
                <th>Valor</th>
                <th>Quantidade / Frequência</th>
                <th>Obs</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </Table>
        </>
      );
    };

    return (
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Home.Proposal.color} label="Serviços" text="" />
        <Space h="xs" />

        {buildPackagingAndEquipment()}
        <Space h="lg" />

        {buildSupplier()}
      </Paper>
    );
  };

  return (
    <Paper shadow="xs" p="md" withBorder>
      {buildResidues()}
      <Space h="lg" />
      {buildServices()}
    </Paper>
  );
}
