/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
import {
  CalendarStats,
  Check,
  CurrencyDollar,
  Edit,
  EyeCheck,
  Icon,
  QuestionMark,
  Scale,
  X,
} from 'tabler-icons-react';
import { Action } from '../../models/core/core.type';
import { Feature } from '../../utils/constants.utils';
import { ProposalResidueType } from '../../models/core/proposals.type';

export enum PageFilter {
  Comercial = 'commercial',
  Financeiro = 'financial',
  Operacional = 'operational',
}

export enum EventBulkUpdateEnum {
  Excluir = 'excluir',
  Responsavel = 'responsavel',
  Resumo = 'resumo',
  Status = 'status',
}

export enum Status {
  Agendado = 'AG',
  Cancelado = 'CA',
  Finalizado = 'FI',
  EmRevisao = 'ER',
  Pendente = 'PE',
  PendenteMedicao = 'PM',
  PendenteFaturamento = 'PF',
}

export enum StatusCodigo {
  AG = 'Agendado',
  CA = 'Cancelado',
  FI = 'Finalizado',
  ER = 'Em Revisão',
  PE = 'Pendente',
  PM = 'Pendente de Medição',
  PF = 'Pendente de Faturamento',
}

export enum Fonte {
  Contrato = 'Contrato',
  Entidade = 'Entidade',
  Proposta = 'Proposta',
}

export enum Audiencia {
  Comercial = 'Comercial',
  Financeiro = 'Financeiro',
  Operacional = 'Operacional',
}

export enum AudienciaCodigo {
  Comercial = 'COM',
  Financeiro = 'FIN',
  Operacional = 'OPE',
}

export enum Tipo {
  Cobranca = 'Cobrança',
  Coleta = 'Coleta',
  Comissao = 'Comissão',
  Documetacao = 'Documentação',
  Fechamento = 'Fechamento',
  Expiracao = 'Expiração',
  Lembrete = 'Lembrete',
  MobilizacaoEnviar = 'Mobilização - Envio',
  MobilizacaoRetirar = 'Mobilização - Retirada',
}

export enum TipoCodigo {
  Cobranca = 'COB',
  Coleta = 'COL',
  Comissao = 'COM',
  Documetacao = 'DOC',
  Expiracao = 'EXP',
  Fechamento = 'FEC',
  Lembrete = 'LEM',
  MobilizacaoEnviar = 'MOE',
  MobilizacaoRetirar = 'MOR',
}

export enum TipoCodigo2 {
  COB = 'Cobrança',
  COL = 'Coleta',
  COM = 'Comissão',
  DOC = 'Documentação',
  FEC = 'Fechamento',
  EXP = 'Expiração',
  LEM = 'Lembrete',
  MOE = 'Mobilização - Envio',
  MOR = 'Mobilização - Retirada',
}

export enum Referencia {
  ContratoComissao = 'Comissão',
  ContratoResiduoAcondicionamento = 'Acondicionamento',
  ContratoResiduoEquipamento = 'Equipamento',
  ContratoResiduoPlano = 'Plano de Coleta',
  ContratoServico = 'Serviço',
  EntidadeDocumento = 'Documento',
}

export enum ReferenciaCodigo {
  ContratoComissao = 'CCO',
  ContratoResiduoAcondicionamento = 'CRA',
  ContratoResiduoEquipamento = 'CRE',
  ContratoResiduoPlano = 'CRP',
  ContratoServico = 'CSE',
  EntidadeDocumento = 'EDO',
}

export enum Relacao {
  Cobranca = 'COB',
  VeiculoCompartilhado = 'VCO',
  Importado = 'IMP',
  Origem = 'ORI',
  Relacionado = 'REL',
  Substituicao = 'SUB',
}

export enum RelacaoCodigo {
  COB = 'Cobranca',
  VCO = 'Veículo Compartilhado',
  IMP = 'Importado',
  ORI = 'Origem',
  REL = 'Relacionado',
  SUB = 'Substituicao',
}

const map = [
  {
    currentStatus: Status.Pendente,
    validStatuses: [
      {
        tipo: TipoCodigo.Cobranca,
        statuses: [Status.Cancelado, Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.Coleta,
        statuses: [Status.Cancelado, Status.Agendado],
      },
      {
        tipo: TipoCodigo.Comissao,
        statuses: [Status.Cancelado, Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.Documetacao,
        statuses: [Status.Cancelado, Status.Finalizado],
      },
      {
        tipo: TipoCodigo.Expiracao,
        statuses: [Status.Cancelado, Status.Finalizado],
      },
      {
        tipo: TipoCodigo.Fechamento,
        statuses: [Status.Cancelado, Status.EmRevisao],
      },
      {
        tipo: TipoCodigo.Lembrete,
        statuses: [Status.Cancelado, Status.Finalizado],
      },
      {
        tipo: TipoCodigo.MobilizacaoEnviar,
        statuses: [Status.Cancelado, Status.Agendado],
      },
      {
        tipo: TipoCodigo.MobilizacaoRetirar,
        statuses: [Status.Cancelado, Status.Agendado],
      },
    ],
  },
  {
    currentStatus: Status.Agendado,
    validStatuses: [
      {
        tipo: TipoCodigo.Coleta,
        statuses: [Status.Agendado, Status.Cancelado, Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.MobilizacaoEnviar,
        statuses: [Status.Agendado, Status.Cancelado, Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.MobilizacaoRetirar,
        statuses: [Status.Agendado, Status.Cancelado, Status.PendenteMedicao],
      },
    ],
  },
  {
    currentStatus: Status.PendenteMedicao,
    validStatuses: [
      {
        tipo: TipoCodigo.Cobranca,
        statuses: [Status.Cancelado, Status.Finalizado],
      },
      {
        tipo: TipoCodigo.Coleta,
        statuses: [Status.Cancelado, Status.Finalizado, Status.Pendente],
      },
      {
        tipo: TipoCodigo.Comissao,
        statuses: [Status.Cancelado, Status.Finalizado],
      },
      {
        tipo: TipoCodigo.MobilizacaoEnviar,
        statuses: [Status.Cancelado, Status.Finalizado, Status.Pendente],
      },
      {
        tipo: TipoCodigo.MobilizacaoRetirar,
        statuses: [Status.Cancelado, Status.Finalizado, Status.Pendente],
      },
    ],
  },
  {
    currentStatus: Status.EmRevisao,
    validStatuses: [
      {
        tipo: TipoCodigo.Fechamento,
        statuses: [Status.Pendente, Status.Cancelado, Status.PendenteFaturamento],
      },
    ],
  },
  {
    currentStatus: Status.PendenteFaturamento,
    validStatuses: [
      {
        tipo: TipoCodigo.Fechamento,
        statuses: [Status.Pendente, Status.Cancelado, Status.Finalizado],
      },
    ],
  },
  {
    currentStatus: Status.Cancelado,
    validStatuses: [],
  },
  {
    currentStatus: Status.Finalizado,
    validStatuses: [
      {
        tipo: TipoCodigo.Cobranca,
        statuses: [Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.Coleta,
        statuses: [Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.Comissao,
        statuses: [Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.MobilizacaoEnviar,
        statuses: [Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.MobilizacaoRetirar,
        statuses: [Status.PendenteMedicao],
      },
      {
        tipo: TipoCodigo.Fechamento,
        statuses: [Status.Pendente],
      },
    ],
  },
];

const getValidStatuses = (tipo: TipoCodigo, currentStatusCode: Status): Status[] => {
  return (
    map.find((x) => x.currentStatus === currentStatusCode)?.validStatuses?.find((y) => y.tipo === tipo)
      ?.statuses || []
  );
};

const getStatusAction = (currentStatusCode: Status): { action: string; icon: Icon } => {
  switch (currentStatusCode) {
    case Status.Pendente:
      return { action: 'Editar', icon: Edit };
    case Status.Agendado:
      return { action: 'Agendar', icon: CalendarStats };
    case Status.EmRevisao:
      return { action: 'Revisar', icon: EyeCheck };
    case Status.PendenteMedicao:
      return { action: 'Enviar para Medição', icon: Scale };
    case Status.PendenteFaturamento:
      return { action: 'Enviar para Faturamento', icon: CurrencyDollar };
    case Status.Cancelado:
      return { action: 'Cancelar', icon: X };
    case Status.Finalizado:
      return { action: 'Finalizar', icon: Check };
    default:
      return { action: '?', icon: QuestionMark };
  }
};

const validarStatusWorkflow = (tipo: TipoCodigo, newStatusCode: Status, currentStatusCode: Status) => {
  if (
    map
      .find((x) => x.currentStatus === currentStatusCode)
      ?.validStatuses?.find((y) => y.tipo === tipo)
      ?.statuses.findIndex((y) => y === newStatusCode) === -1
  ) {
    throw Error('Não é possível atualizar esse evento com esse novo status.');
  }
};

const gerenciarVeiculoCompartilhado = (evento: any): boolean => {
  return (
    (evento.codigoEventoTipo === TipoCodigo.Coleta &&
      evento.veiculo &&
      evento.relacoes.findIndex(
        (x: any) =>
          x.codigoEventoRelacao === Relacao.VeiculoCompartilhado &&
          x.idEventoReferenciado === evento.idEvento &&
          x.action !== Action.Delete
      ) === -1 &&
      (evento.codigoEventoStatus === Status.Pendente || evento.codigoEventoStatus === Status.Agendado)) ??
    false
  );
};

const gerenciarVeiculoCompartilhadoMovimentacao = (evento: any): boolean => {
  return (
    evento.codigoEventoTipo === TipoCodigo.Coleta &&
    evento.codigoEventoStatus === Status.PendenteMedicao &&
    evento.veiculo
  );
};

const obterMapeamentoReferencia2 = (
  fonte: Fonte,
  idPropostaResiduo: number | undefined | null,
  codigoEventoReferencia: ReferenciaCodigo,
  propostaResiduoData: ProposalResidueType | null,
  adicionarPersonalidado: boolean
) => {
  if (
    fonte === Fonte.Contrato &&
    idPropostaResiduo &&
    codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento &&
    propostaResiduoData
  ) {
    const itens = [];

    for (const plano of propostaResiduoData?.planos || []) {
      for (const acondicionamento of plano.acondicionamentos) {
        itens.push({
          ...acondicionamento,
          cotacoes: plano.cotacoes
            .map((x) => {
              return x.acondicionamentos.map((y) => {
                return { ...y, primario: x.primario };
              });
            })
            .flat()
            .filter(
              (y) =>
                y.idPropostaResiduoPlanoAcondicionamento ===
                acondicionamento.idPropostaResiduoPlanoAcondicionamento
            ),
          grupo: `Plano ${plano.primario ? 'Primário' : 'Alternativo'} (#${plano.idPropostaResiduoPlano})`,
          primario: plano.primario,
        });
      }
    }
    if (adicionarPersonalidado) {
      itens.push({
        idPropostaResiduoPlanoAcondicionamento: -1,
        residuoAcondicionamento: 'Outro',
        grupo: 'Personalizado',
        primario: false,
      });
    }

    return (
      itens
        .sort((a, b) => Number(a.primario ? 0 : 1) - Number(b.primario ? 0 : 1))
        .map((y: any) => {
          return {
            label: `${y.residuoAcondicionamento}`,
            value: y.idPropostaResiduoPlanoAcondicionamento!.toString(),
            group: y.grupo,
            primary: y.primario.toString(),
            extras:
              y.cotacoes?.map((c: any) => {
                return {
                  idPropostaResiduoPlanoCotacao: c.idPropostaResiduoPlanoCotacao,
                  cotacao: `#${c.idPropostaResiduoPlanoCotacao}`,
                  primary: c.primario,
                  itens: [
                    {
                      icone: Feature.Reference.Packaging.icon,
                      fornecedor:
                        c.fornecedorNomeFantasia ?? c.fornecedorRazaoSocial ?? c.fornecedorNome ?? '-',
                    },
                  ],
                };
              }) ?? [],
          };
        }) || []
    );
  }

  if (
    fonte === Fonte.Contrato &&
    idPropostaResiduo &&
    codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento &&
    propostaResiduoData
  ) {
    const itens = [];

    for (const plano of propostaResiduoData?.planos || []) {
      for (const equipamento of plano.equipamentos) {
        itens.push({
          ...equipamento,
          cotacoes: plano.cotacoes
            .map((x) => {
              return x.equipamentos.map((y) => {
                return { ...y, primario: x.primario };
              });
            })
            .flat()
            .filter(
              (y) => y.idPropostaResiduoPlanoEquipamento === equipamento.idPropostaResiduoPlanoEquipamento
            ),
          grupo: `Plano ${plano.primario ? 'Primário' : 'Alternativo'} (#${plano.idPropostaResiduoPlano})`,
          primario: plano.primario,
        });
      }
    }
    if (adicionarPersonalidado) {
      itens.push({
        idPropostaResiduoPlanoEquipamento: -1,
        residuoEquipamento: 'Outro',
        grupo: 'Personalizado',
        primario: false,
      });
    }

    return (
      itens
        .sort((a, b) => Number(a.primario ? 0 : 1) - Number(b.primario ? 0 : 1))
        .map((y: any) => {
          return {
            label: `${y.residuoEquipamento}`,
            value: y.idPropostaResiduoPlanoEquipamento!.toString(),
            group: y.grupo,
            primary: y.primario.toString(),
            extras:
              y.cotacoes?.map((c: any) => {
                return {
                  idPropostaResiduoPlanoCotacao: c.idPropostaResiduoPlanoCotacao,
                  cotacao: `#${c.idPropostaResiduoPlanoCotacao}`,
                  primary: c.primario,
                  itens: [
                    {
                      icone: Feature.Reference.Equipment.icon,
                      fornecedor:
                        c.fornecedorNomeFantasia ?? c.fornecedorRazaoSocial ?? c.fornecedorNome ?? '-',
                    },
                  ],
                };
              }) ?? [],
          };
        }) || []
    );
  }
  if (
    fonte === Fonte.Contrato &&
    idPropostaResiduo &&
    codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano &&
    propostaResiduoData
  ) {
    const itens = [];

    for (const plano of propostaResiduoData?.planos || []) {
      let nome = `${plano.veiculo?.residuoVeiculo ?? '-'}|${plano.tratamento?.residuoTratamento ?? '-'}|${
        plano.destinoFinal?.residuoDestinoFinal ?? '-'
      }`;
      nome = nome.replaceAll('-|', '');
      nome = nome.replaceAll('-|-', '');
      nome = nome.replaceAll('|-', '');
      nome = nome.replaceAll('|', ' | ');

      itens.push({
        ...plano,
        nome,
        cotacoes: plano.cotacoes,
        grupo: `Plano ${plano.primario ? 'Primário' : 'Alternativo'} (#${plano.idPropostaResiduoPlano})`,
        primario: plano.primario,
      });
    }
    if (adicionarPersonalidado) {
      itens.push({
        idPropostaResiduoPlano: -1,
        nome: 'Outro',
        grupo: 'Personalizado',
        primario: false,
      });
    }

    return (
      itens
        .sort((a, b) => Number(a.primario ? 0 : 1) - Number(b.primario ? 0 : 1))
        .map((y: any) => {
          return {
            label: `${y.nome}`,
            value: y.idPropostaResiduoPlano!.toString(),
            group: y.grupo,
            primary: y.primario.toString(),
            extras:
              y.cotacoes?.map((c: any) => {
                return {
                  idPropostaResiduoPlanoCotacao: c.idPropostaResiduoPlanoCotacao,
                  cotacao: `#${c.idPropostaResiduoPlanoCotacao}`,
                  primary: c.primario,
                  itens: [
                    c?.veiculo
                      ? {
                          icone: Feature.Reference.Vehicle.icon,
                          fornecedor:
                            c.veiculo?.fornecedorNomeFantasia ??
                            c.veiculo?.fornecedorRazaoSocial ??
                            c.veiculo?.fornecedorNome ??
                            '-',
                        }
                      : undefined,
                    c?.tratamento
                      ? {
                          icone: Feature.Reference.Treatment.icon,
                          fornecedor:
                            c.tratamento?.fornecedorNomeFantasia ??
                            c.tratamento?.fornecedorRazaoSocial ??
                            c.tratamento?.fornecedorNome ??
                            '-',
                        }
                      : undefined,
                    c?.destinoFinal
                      ? {
                          icone: Feature.Reference.Destination.icon,
                          fornecedor:
                            c.destinoFinal?.fornecedorNomeFantasia ??
                            c.destinoFinal?.fornecedorRazaoSocial ??
                            c.destinoFinal?.fornecedorNome ??
                            '-',
                        }
                      : undefined,
                  ].filter((e) => e),
                };
              }) ?? [],
          };
        }) || []
    );
  }

  return [];
};

export {
  gerenciarVeiculoCompartilhado,
  gerenciarVeiculoCompartilhadoMovimentacao,
  getStatusAction,
  getValidStatuses,
  validarStatusWorkflow,
  obterMapeamentoReferencia2,
};
