/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Alert,
  Badge,
  Group,
  Input,
  Paper,
  SegmentedControl,
  SimpleGrid,
  Space,
  Textarea,
  Tooltip,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { Table } from 'antd';
import { forwardRef, useImperativeHandle } from 'react';
import { AlertTriangle, Calendar, InfoCircle, Notes } from 'tabler-icons-react';
import { ProposalStatusEnum } from '../../../../../business/proposals/status';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ContractSearchResponseType } from '../../../../../models/core/contracts.type';
import { ProposalType } from '../../../../../models/core/proposals.type';
import theme from '../../../../../theme';
import { formatDateStringToDate, formatDateToString } from '../../../../../utils/formatter.utils';
import { now, tryGetDateISOFormat } from '../../../../../utils/helper.utils';

type FormViewData = {
  dataInicial: Date | undefined;
  dataFinal: Date | undefined;
  novoContrato: string;
  idContrato: number | null;
  observacao: string | null;
};

type FormViewProps = {
  proposal: ProposalType;
  contracts: ContractSearchResponseType[];
  newStatusCode: ProposalStatusEnum;
};

const StatusChangeForm = forwardRef((props: FormViewProps, ref) => {
  const form = useForm<FormViewData>({
    initialValues: {
      dataInicial: props.proposal
        ? formatDateStringToDate(tryGetDateISOFormat(props.proposal.dataInicial))
        : now().toDate(),

      dataFinal: props.proposal
        ? formatDateStringToDate(tryGetDateISOFormat(props.proposal.dataFinal))
        : now().toDate(),

      novoContrato: true.toString(),
      idContrato: null,
      observacao: '',
    },
    validate: {
      dataInicial: (value) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
        }
        return null;
      },
      dataFinal: (value, values: FormViewData) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
          if (value < values.dataInicial!) {
            return 'Data final não pode ser anterior à data inicial';
          }
        }
        return null;
      },
      idContrato: (value, values: FormViewData) => {
        if ((value === null || value === undefined) && values.novoContrato === false.toString()) {
          return 'Selecione um contrato para ser substituído';
        }
        return null;
      },
    },
  });

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ContractSearchResponseType[]) => {
      form.setFieldValue('idContrato', Number(selectedRows?.[0].idContrato) || null);
    },
    getCheckboxProps: (record: ContractSearchResponseType) => {
      return {
        name: record.idContrato.toString(),
      };
    },
  };

  useImperativeHandle(ref, () => ({
    validate(): FormViewData | null {
      if (props.newStatusCode === ProposalStatusEnum.Finalizado && form.validate().hasErrors) {
        return null;
      }

      const response = { ...form.values, observacao: form.values.observacao?.trim() || null };
      if (props.newStatusCode !== ProposalStatusEnum.Finalizado) {
        response.dataInicial = undefined;
        response.dataFinal = undefined;
      }

      if (form.values.novoContrato === true.toString()) {
        response.idContrato = null;
      }

      return response;
    },
  }));

  return (
    <form id="status-change-form" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <div hidden={props.newStatusCode !== ProposalStatusEnum.Finalizado}>
          <SimpleGrid cols={props.contracts.length === 0 ? 2 : 3}>
            <div>
              <Input.Wrapper label="Data Inicial do Contrato" required>
                <Group
                  style={{
                    display: 'inline-block',
                    marginLeft: 5,
                    marginTop: -2,
                    verticalAlign: 'bottom',
                  }}
                >
                  <Tooltip
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Data prevista de início do contrato"
                  >
                    <div>
                      <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                    </div>
                  </Tooltip>
                </Group>
              </Input.Wrapper>
              <DatePicker
                icon={<Calendar size={15} />}
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                required
                {...form.getInputProps('dataInicial')}
              />
            </div>

            <div>
              <Input.Wrapper label="Data Final do Contrato" required>
                <Group
                  style={{
                    display: 'inline-block',
                    marginLeft: 5,
                    marginTop: -2,
                    verticalAlign: 'bottom',
                  }}
                >
                  <Tooltip
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Data prevista de fim do contrato"
                  >
                    <div>
                      <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                    </div>
                  </Tooltip>
                </Group>
              </Input.Wrapper>
              <DatePicker
                icon={<Calendar size={15} />}
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                minDate={form.values.dataInicial}
                required
                {...form.getInputProps('dataFinal')}
              />
            </div>

            {props.contracts.length > 0 && (
              <div>
                <Input.Wrapper label="Ação de Contrato" required>
                  <Group
                    style={{ display: 'inline-block', marginLeft: 5, marginTop: -2, verticalAlign: 'bottom' }}
                  >
                    <Tooltip
                      withArrow
                      transition="fade"
                      transitionDuration={200}
                      label="Esse cliente já possui um ou mais contratos ativos. O que você gostaria de fazer?"
                      multiline
                      width={350}
                    >
                      <div>
                        <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                      </div>
                    </Tooltip>
                  </Group>
                </Input.Wrapper>
                <SegmentedControl
                  color="primary"
                  data={[
                    { label: 'Novo', value: true.toString() },
                    { label: 'Substituição', value: false.toString() },
                  ]}
                  {...form.getInputProps('novoContrato')}
                />
              </div>
            )}
          </SimpleGrid>
          <Space h="xs" />

          {form.values.novoContrato === false.toString() && (
            <div>
              <SimpleGrid>
                <Paper shadow="xs" p="md" withBorder>
                  {!form.values.idContrato && (
                    <div>
                      <Alert icon={<AlertTriangle size={32} />} title="Atenção" color="yellow">
                        Favor selecionar um contrato para ser substituído.
                      </Alert>
                      <Space h="xs" />
                    </div>
                  )}

                  <Table
                    showSorterTooltip={false}
                    size="small"
                    dataSource={props.contracts}
                    columns={[
                      {
                        title: 'Contrato #',
                        key: 'idContrato',
                        sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) => {
                          const aValue = a.idContrato;
                          const bValue = b.idContrato;
                          if (aValue === bValue) {
                            return 0;
                          }
                          return aValue > bValue ? 1 : -1;
                        },
                        render: (row: ContractSearchResponseType) => (
                          <ProfileCardLink
                            id={row.idContrato.toString()}
                            name="Contrato"
                            nameSize="sm"
                            description={`# ${row.idContrato}`}
                            descriptionSize="xs"
                            linkPrefix="contracts"
                            showLink
                          />
                        ),
                      },
                      {
                        title: 'Proposta #',
                        key: 'idProposta',
                        sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) => {
                          const aValue = a.idProposta;
                          const bValue = b.idProposta;
                          if (aValue === bValue) {
                            return 0;
                          }
                          return aValue > bValue ? 1 : -1;
                        },
                        render: (row: ContractSearchResponseType) => (
                          <ProfileCardLink
                            id={row.idProposta.toString()}
                            name="Proposta"
                            nameSize="sm"
                            description={`# ${row.idProposta}`}
                            descriptionSize="xs"
                            linkPrefix="proposals"
                            showLink
                          />
                        ),
                      },
                      {
                        title: 'Status',
                        key: 'status',
                        sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) =>
                          a.contratoStatus.localeCompare(b.contratoStatus),
                        render: (row: ContractSearchResponseType) => (
                          <Badge variant="outline">{row.contratoStatus}</Badge>
                        ),
                      },
                      {
                        title: 'Data Inicial',
                        key: 'dataInicial',
                        sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) =>
                          new Date(a.dataInicial).valueOf() - new Date(b.dataInicial).valueOf(),
                        render: (row: ContractSearchResponseType) => formatDateToString(row.dataInicial),
                      },
                      {
                        title: 'Data Final',
                        key: 'dataFinal',
                        sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) =>
                          new Date(a.dataFinal).valueOf() - new Date(b.dataFinal).valueOf(),
                        render: (row: ContractSearchResponseType) => formatDateToString(row.dataFinal),
                      },
                    ]}
                    rowSelection={{ type: 'radio', ...rowSelection }}
                    rowKey={(item: ContractSearchResponseType) => item.idContrato}
                    pagination={{
                      pageSizeOptions: [10, 25, 50],
                      showSizeChanger: true,
                      showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                    }}
                  />
                </Paper>
              </SimpleGrid>
              <Space h="xs" />
            </div>
          )}
        </div>

        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o status"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
    </form>
  );
});

export default StatusChangeForm;
